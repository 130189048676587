import React, { PureComponent } from "react";
import Paper from "@material-ui/core/Paper";
import { withTheme } from "@material-ui/core/styles";
import { Button, Tooltip, Grid } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";

class MapOptions extends PureComponent {

  render() {
    const {
      isPlayground,
      onSaveClick,
      setShowDownloadOnMergeDialog,
      redo,
      undo,
      canRedo,
      canUndo,
      geoCoderContainerRef
    } = this.props;

    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{
            marginRight: 10,
            marginTop: 5,
            alignItems: "flex-end",
            padding: this.props.theme.spacing(0.5)
          }}
          className="hideInSS"
        >
          <Grid item style={{display: "flex"}}>
            <Paper
              style={{
                ...this.props.style,
                display: "flex",
                justifyContent: "space-between",
                padding: this.props.theme.spacing(0.5)
              }}
            >
              <Tooltip placement="left-start" title="Redo last change">
                <span>
                  <Button
                    disabled={!canRedo()}
                    onClick={redo}
                    style={{
                      opacity: 0.87,
                      borderRadius: 0,
                      borderRightColor: "#e0e0e0",
                      borderRightStyle: "solid",
                      borderRightWidth: 2,
                      minWidth: 56
                    }}
                  >
                    <RedoIcon style={{ fontSize: 20 }} />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip placement="left-start" title="Undo last change">
                <span>
                  <Button
                    style={{minWidth: 55}}
                    disabled={!canUndo()}
                    onClick={undo}>
                    <UndoIcon style={{ fontSize: 20 }} />
                  </Button>
                </span>
              </Tooltip>
            </Paper>

            <Paper
              style={{
                ...this.props.style,
                display: "flex",
                justifyContent: "space-between",
                padding: this.props.theme.spacing(0.5),
                marginLeft: this.props.theme.spacing(2)
              }}
            >
              <Tooltip placement="left-start" title="Save zones">
                <span>
                  <Button
                    className='saveButton' // for joyride
                    disabled={isPlayground}
                    variant="text"
                    onClick={onSaveClick}
                    style={{
                      opacity: 0.87,
                      borderRadius: 0,
                      borderRightColor: "#e0e0e0",
                      borderRightStyle: "solid",
                      borderRightWidth: 2,
                      minWidth: 55
                    }}
                  >
                    <SaveIcon style={{ fontSize: 20 }} />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip placement="left-start" title="Download zones">
                <Button
                  className='downloadButton' // for joyride
                  variant="text"
                  onClick={() => setShowDownloadOnMergeDialog(true)}
                  style={{
                    borderColor: "#e0e0e0",
                    opacity: 0.87,
                    fontSize: 28,
                    minWidth: 55
                  }}
                >
                  <CloudDownloadIcon style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Paper>
          </Grid>
          <Grid item>
            <div
              ref={geoCoderContainerRef}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTheme(MapOptions);
