import React from "react";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const FeatureDescription = props => {
  const { visible, text } = props;
  if (!visible) return null;
  return (
    <React.Fragment>
      <Paper
        style={{
          position: "absolute",
          left: "50%",
          width: 260,
          marginRight: 105,
          top: 22,
          zIndex: 999,
          padding: 8,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          style={{
            textAlign: "center",
            color: "#A2BAD2",
            fontWeight: 600
          }}
        >
          {text}
        </Typography>
      </Paper>
    </React.Fragment>
  );
};

export default FeatureDescription;
