import React, { PureComponent } from "react";
import { withTheme } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import { withSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router-dom";
import { Button} from "@material-ui/core";
import logo from "../../assets/brand/SVG/standard.svg";

class Header extends PureComponent {

  goToFAQ = async () => {
    window.open("https://faq.geowiz.tech/", "_blank")
  }

  goToPlayground = async () => {
    this.props.history.push(
      `/playground`
    );
  };

  goToAdminPanel = async () => {
    console.log("Go to admin panel")
  }

  getCompanyStyle = () => {
    return styles.voiNameStyle;
  }

  render() {
    return (
      <header
        style={styles.headerStyle}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="geowiz"
            src={logo}
            style={styles.logoStyle}
          />

          <img
            alt="companyName"
            src={this.props.companyLogo}
            style={this.getCompanyStyle()}
          ></img>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button onClick={() => this.goToFAQ()} style={{fontWeight: 400, borderRadius: 40}} variant="text">FAQ</Button>
          {this.props.children}
          <Button
            onClick={() => this.goToPlayground()}
            variant="contained"
            style={styles.buttonStyle}
          >
            <EditIcon
              style={{ fontSize: 16, color: "#2B2B2B", marginRight: 8 }}
            ></EditIcon>
            Launch Playground
          </Button>
        </div>
      </header>
    );
  }
}

let styles = {
  headerStyle: {
    maxWidth: 1190,
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 4,
    paddingBottom: 4,
    marginRight: 12,
    marginLeft: 12
  },
  logoStyle: {
    width: 130,
    height: "auto",
  },
  voiNameStyle: {
    width: "55px",
    height: "auto",
    paddingLeft: 12,
    marginLeft: 12,
    paddingBottom: 7,
    borderLeftColor: "lightgrey",
    borderLeftStyle: "solid",
    borderLeftWidth: 1
  },
  buttonStyle: {
    borderRadius: 40,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 12,
    color: "#2B2B2B",
    backgroundColor: "#F4F4F5",
    WebkitBoxShadow: "none",
    MozBoxShadow: "none",
    boxShadow: "none"
  }
}

export default withRouter(withSnackbar(withTheme(withFirebase(Header))));
