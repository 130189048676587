import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import { DELETE_USER_URL } from '../../constants/remotes';
import axios from "axios";
import { withTheme } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { withFirebase } from "../Firebase";

class DeleteUserDialog extends Component {

  deleteUser = async () => {
    const {userMail, setShowConfirmDelete, companyName, refreshUsers} = this.props;
    try{
      const res = await axios.post(
        DELETE_USER_URL,
        {userMail, companyName},
        {
          headers: {
            Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
          }
        }
      );
      if(res.status === 200){
        this.props.enqueueSnackbar("Successfully deleted " + userMail, { 
          variant: "success",
          autoHideDuration: 4000
        });
      }else if(res.status === 401){
        this.props.enqueueSnackbar("Not authorized to delete users", { 
          variant: "error",
          autoHideDuration: 4000
        });
      }
    }catch(error){
      this.props.enqueueSnackbar("Something went wrong, try again", {
        variant: "error",
        autoHideDuration: 4000
      });
      console.log(error)
    }
    setShowConfirmDelete(false);
    refreshUsers();
  }

  render() {
    const { showConfirmDelete, setShowConfirmDelete, userMail } = this.props;
    return (
      <Dialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
      >
        <DialogTitle>
            Deleting user
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <b>{userMail}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
          <Button onClick={this.deleteUser}>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withFirebase(withTheme(withSnackbar(DeleteUserDialog)));
