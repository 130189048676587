import React, { PureComponent } from "react";
import { Button, TableRow, TableCell } from "@material-ui/core";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ChangeCitiesDialog from './ChangeCitiesDialog';
import DeleteUserDialog from './DeleteUserDialog';

class UserListItemEntry extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      changeCitiesIsOpen: false,
      confirmDeleteIsOpen: false,
      userMail: this.props.userMail,
    };
  }

  setShowChangeCities = (changeCitiesIsOpen) => {
    this.setState({changeCitiesIsOpen});
  }

  setShowConfirmDelete = (confirmDeleteIsOpen) => {
    this.setState({confirmDeleteIsOpen})
  }

  render() {
    const {userMail, isAdmin, accessList, cityList, companyName, refreshUsers} = this.props;
    const {changeCitiesIsOpen, confirmDeleteIsOpen} = this.state;
    return (
      <TableRow>
        <TableCell>
          {userMail}
        </TableCell>
        <TableCell align="left">
          {isAdmin?
            "Admin"
            :
            "User"}
        </TableCell>
        <TableCell>
          <div style={{display: "flex", flexDirection: "row", float: "right"}}>
            <Button
              disabled={isAdmin}
              onClick={this.setShowChangeCities}
              variant="contained"
              style={isAdmin?{...styles.buttonStyle, opacity: 0.4}: styles.buttonStyle}
            >
              <LocationCityIcon
                style={{ fontSize: 22, color: "#2B2B2B", marginRight: 8 }}
              ></LocationCityIcon>
                Change Cities
            </Button>
            <ChangeCitiesDialog
              setShowChangeCities={this.setShowChangeCities}
              showChangeCities={changeCitiesIsOpen}
              cityList={cityList}
              accessList={accessList}
              userMail={userMail}
              companyName={companyName}/>
            <div style={{margin: "10px 0px 10px 10px"}}>
              <DeleteForeverIcon 
                style={{ fontSize: 22, color: "#2B2B2B", cursor: "pointer" }}
                onClick={this.setShowConfirmDelete}>
              </DeleteForeverIcon>
              <DeleteUserDialog 
                setShowConfirmDelete={this.setShowConfirmDelete}
                showConfirmDelete={confirmDeleteIsOpen}
                userMail={userMail}
                companyName={companyName}
                refreshUsers={refreshUsers}/>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

let styles = {
  buttonStyle: {
    borderRadius: 40,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 12,
    color: "#2B2B2B",
    backgroundColor: "#F4F4F5",
    WebkitBoxShadow: "none",
    MozBoxShadow: "none",
    boxShadow: "none",
    zIndex: 0
  }
}

export default UserListItemEntry;
