import React, { Component } from 'react';
import {
  DialogActions, Button, Checkbox, Typography,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import LayerTag from "./LayerTag";
import CustomDialog from "./CustomDialog";

class MergeLayerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additiveLayerName: "",
      additiveLayerId: null,
      checkedLayers: []
    }
  }

  handleAdditiveLayerChange = event => {
    const additiveLayer = this.props.layers.filter(layer => layer.layerName === event.target.value)[0];
    this.setState({ additiveLayerName: additiveLayer.layerName, additiveLayerId: additiveLayer.id })
  }

  renderSelectionItems() {
    const layers = this.props.layers.filter(layer => layer.id !== this.props.layerToBeEdited && !layer.hidden);
    return layers.map(layer => (
      <div key={layer.layerName} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox key={layer.layerName} color="primary" id={layer.layerName} onChange={() => {
          const { checkedLayers } = this.state;
          if (checkedLayers.includes(layer.layerName)) {
            checkedLayers.splice(checkedLayers.indexOf(layer.layerName), 1);
            this.setState({ checkedLayers });
            return;
          }
          checkedLayers.push(layer.layerName);
        }}>
        </Checkbox>
        <LayerTag key={layer.layerName} editing layerType={layer.layerType}></LayerTag>
        <Typography key={layer.layerName} style={{ marginLeft: 10 }}>{layer.layerName}</Typography>
      </div>
    ))
  }

  renderDialogContent = () => {
    return (
      <div style={{
        marginBottom: 16,
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
      }} >
        <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
          {this.renderSelectionItems()}
        </div>
      </div>
    )
  }

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
        }}
      >
        <Button variant="contained" style={{ backgroundColor: "#4a5bbb", color: "white" }} onClick={() => {
          if (this.props.dataToUpload) {
            this.props.mergeWithExistingData(this.state.checkedLayers);
            this.setState({checkedLayers: []});
          } else {
            this.props.mergeLayers(this.state.checkedLayers, true);
            this.setState({checkedLayers: []});
          }
        }
        }>
        Merge Layers
        </Button>
      </DialogActions>
    )
  }

  getLayerName(id)  {
    try {
      let {layers} = this.props
      layers = layers.filter(layer => layer.id === id);
      return layers[0].layerName
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { setShowMergeLayers, showMergeLayers, dataToUpload, layerToBeEdited} = this.props;
    if (!showMergeLayers) return null;
    return (
      <CustomDialog
        open = {showMergeLayers}
        onClose = {setShowMergeLayers}
        renderDialogActions = {this.renderDialogActions}
        renderDialogContent = {this.renderDialogContent}
        title = {dataToUpload?  "Merge with existing data" : "Merge layers"}
        subTitle = {dataToUpload?
          "Select the zones you want to merge your file with" :
          `Select the layers you want to merge into '${this.getLayerName(layerToBeEdited)}'`
        }
      />
    )
  }
}

export default withTheme(MergeLayerDialog);
