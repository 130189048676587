import React, { Component } from "react";
import { Paper, Typography, Box, Tooltip } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import LayerMenu from "./LayerMenu";
import LayerTag from "./LayerTag";

class LayerControls extends Component {
  getLayerDisplayName(layerName) {
    if (layerName.length > 18) {
      layerName = layerName.slice(0, 15) + "...";
    }
    return layerName;
  }

  render() {
    const { visible, editing, id, layerName, hidden } = this.props.layer;
    const muiDisabledStyle = editing ? "" : "Mui-disabled";
    const className = `MuiButton-root ${muiDisabledStyle}`;
    return (
      <Paper
        elevation={2}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          width: "100%",
          maxHeight: 55
        }}
      >
        {/* EYE ICON  */}
        <Box
          style={{
            cursor: hidden ? "default" : "pointer"
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="20%"
          height="60px"
          onClick={e => { this.props.onVisibilityChange(id); e.stopPropagation(); }}
        >
          {hidden ?
            <Tooltip
              title = "This layer is disabled"
            >
              <VisibilityOff
                style={{color: "grey"}}
              />
            </Tooltip>
            :
            <Tooltip
              title={visible ? "Hide" : "Show"}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </Tooltip>
          }
        </Box>
        <div
          className={className}
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textTransform: 'none',
            cursor: hidden ? "default" : "pointer",
            borderLeft: "1px solid lightgrey",
            borderRadius: 0
          }}
          onClick={e => { this.props.onLayerEditChange(id); e.stopPropagation(); }}
        >
          <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            {/* LAYERNAME  */}
            <Box m={1}>
              <Typography
                style={{
                  userSelect: 'none',
                  fontSize: 15,
                  fontWeight: editing ? 425 : 400 // debatable
                }}
                align='center'
                noWrap
              >
                {this.getLayerDisplayName(layerName)}
              </Typography>
            </Box>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
              {/* LAYER TAG  */}
              <LayerTag
                editing={editing}
                layerType={this.props.layer.layerType}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
              {/* LAYER MENU */}
              <LayerMenu
                layerId={id}
                setLayerToBeEdited={this.props.setLayerToBeEdited}
                setShowOSMPicker={this.props.setShowOSMPicker}
                onDownloadClick={this.props.onDownloadClick}
                onDeleteClick={this.props.onDeleteClick}
                onRenameClick={this.props.onRenameClick}
                setShowMergeLayers={this.props.setShowMergeLayers}
                changeEditMode={this.props.changeEditMode}
                setShowTransformations={this.props.setShowTransformations}
                setLayerToBeHidden={this.props.setLayerToBeHidden}
                onDuplicateLayer={this.props.onDuplicateLayer}
                onInfoClick={this.props.onInfoClick}
                hidden={hidden}
              />
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default withSnackbar(withTheme(LayerControls));
