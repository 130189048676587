import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { withTheme } from "@material-ui/core/styles";
import CustomDialog from "../Editor/CustomDialog"
import { withSnackbar } from "notistack";
import { withFirebase } from "../Firebase";
import { ADD_NEW_CITY } from "../../constants/remotes";


class AddNewCityDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: props.companyName,
      cityName: ""
    };
  }

  isValidCityName = () => {
    const name = this.state.cityName.toLowerCase().trim();
    const currentNames = this.props.cityNames.map(x => x.name)
    if (name === "" || currentNames.includes(name) ) return false;
    return true;

  }

  addNewCity = async () => {
    const {cityName} = this.state
    if (!this.isValidCityName()) {
      this.props.enqueueSnackbar("Please input a valid city name", { 
        variant: "error",
        autoHideDuration: 4000
      });
      return;
    }
    const res = await axios.post(ADD_NEW_CITY, {company: this.props.company, newCityName: cityName.toLowerCase()},
      {
        headers: {
          Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
        }
      });
    if (res.status === 201) {
      this.props.enqueueSnackbar("City succesfully added", { 
        variant: "success",
        autoHideDuration: 4000
      });
      this.props.setShowNewCityDialog()
      return;
    } else {
      this.props.enqueueSnackbar("Something went wrong", { 
        variant: "error",
        autoHideDuration: 4000
      });
      return;
    }
  }

  handleCityChange = event => {
    this.setState({cityName: event.target.value})
  }

  renderDialogContent = () => {
    const { cityName } = this.state;
    return (
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          style={{ marginBottom: 20, minWidth: 300 }}
          onChange={this.handleCityChange}
          value={cityName}
          autoFocus
          label="City name*"
          autoComplete={"off"}
          margin="dense"
          id="city">
        </TextField>
      </DialogContent>
    );
  }

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          style={{ backgroundColor: "#4a5bbb", color: "white" }}
          variant="contained"
          onClick={() => this.addNewCity()}
        >
        Add city
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { addNewCityDialogIsOpen, setShowNewCityDialog } = this.props;
    return (
      <CustomDialog
        open={addNewCityDialogIsOpen}
        onClose={setShowNewCityDialog}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Add new city"}
      />
    );
  }
}

export default withFirebase(withTheme(withSnackbar(AddNewCityDialog)));
