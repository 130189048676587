import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  Box
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "./CustomDialog";
import { LAYER_TYPE_OPS } from "./Map";


const bufferOptions = [
  // "-10 meters",
  // "-5 meters",
  "0 meters",
  "5 meters",
  "10 meters",
  "20 meters"
];
const OSMTypes = {
  leisure: {
    description: "Leisure & green areas",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "leisure",
        value: null
      },
      {
        key: "landuse",
        value: "recreation_ground"
      },
      {
        key: "landuse",
        value: "forest"
      },
      {
        key: "natural",
        value: "wood"
      }
    ]
  },
  water: {
    description: "Water areas",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "natural",
        value: "water"
      },
      {
        key: "landuse",
        value: "basin"
      },
      {
        key: "landuse",
        value: "reservoir"
      }
    ]
  },
  cemetary: {
    description: "Cemetaries",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "landuse",
        value: "cemetery"
      }
    ]
  },
  school: {
    description: "Schools",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "amenity",
        value: "school"
      }
    ]
  },
  bridge: {
    description: "Bridges",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "man_made",
        value: "bridge"
      }
    ]
  },
  pedestrian: {
    description: "Pedestrian Areas",
    buffer: "0 meters",
    checked: false,
    types: [
      {
        key: "highway",
        value: "pedestrian"
      }
    ]
  }
};

class OSMPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenLayer: null,
      types: OSMTypes,
      isFetching: false
    };
  }

  handleSubmit = async () => {
    this.setState({ isFetching: true });
    const data = await this.props.onSubmit({
      categories: this.state.types,
      layers: this.props.layers,
      bbox: this.props.OSMBoundingBox
    });
    this.setState({ isFetching: false });
    this.props.setShowOSMPicker(false);
    this.props.layers.forEach(l => {
      if (l.editing && l.layerType !== LAYER_TYPE_OPS) {
        this.props.uploadCustomGeoJson(data, l.id);
      }
    });
    this.props.changeEditMode(null, "osmPicker");
  };

  renderDialogContent = () => {
    const types = this.state.types;
    return (
      <DialogContent>
        <DialogContentText>Select type and buffer</DialogContentText>
        <DialogContentText variant="caption">
          {"Layers selected: " +
            this.props.layers.filter(l => l.editing).length}
        </DialogContentText>
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormGroup>
            {Object.keys(types).map(key => (
              <Box
                key={types[key].description}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={types[key].checked}
                      value={types[key].description}
                      onChange={() => {
                        this.setState({
                          types: {
                            ...this.state.types,
                            [key]: {
                              ...types[key],
                              checked: !types[key].checked
                            }
                          }
                        });
                      }}
                    />
                  }
                  label={types[key].description}
                />
                <TextField
                  style={{
                    width: "120px",
                    display: types[key].checked ? "flex" : "none"
                  }}
                  select
                  onChange={e => {
                    this.setState({
                      types: {
                        ...this.state.types,
                        [key]: { ...types[key], buffer: e.target.value }
                      }
                    });
                  }}
                  value={types[key].buffer}
                >
                  {bufferOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
    );
  };

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
        }}
      >
        {this.state.isFetching ? (
          <Button
            variant="contained"
            disabled
            style={{ width: 120, height: 35 }}
            onClick={() => this.handleSubmit()}
          >
            Wait
            <CircularProgress
              disableShrink
              size={14}
              style={{ marginLeft: 10 }}
            />
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "#4a5bbb", color: "white" }} 
            onClick={() => this.handleSubmit()}
          >
            Add zones
          </Button>
        )}
      </DialogActions>
    );
  };

  render() {
    return (
      <CustomDialog
        open={this.props.open}
        onClose={this.props.setShowOSMPicker}
        onSubmit={this.handleSubmit}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Add OSM data"}
      />
    );
  }
}

export default withTheme(OSMPicker);
