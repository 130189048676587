import React from "react";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { red, green, yellow, orange, grey, cyan, blue } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator'
import { getLayerDescription } from "./LayerStyles";

const layerTags = {
  LAYER_TYPE_NPZ: { text: "NPZ", fillColor: red[400] },
  LAYER_TYPE_IPZ: { text: "IPZ", fillColor: green[400] },
  LAYER_TYPE_SSZ: { text: "SSZ", fillColor: yellow[600] },
  LAYER_TYPE_OPS: { text: "OPS", fillColor: cyan[400] },
  LAYER_TYPE_CUSTOM: { text: "CST", fillColor: grey[400] },
  LAYER_TYPE_BOUNTY: { text: "BTY", fillColor: orange[600] },
  LAYER_TYPE_MPZ: { text: "MPZ", fillColor: blue[600] },
  LAYER_TYPE_NGZ: { text: "NGZ", fillColor: grey[800] }
};

const textColor = grey[50];

export default function LayerTag(props) {
  const { layerType, editing } = props;
  const layerTag = layerTags[layerType]
  if (!layerTag) return;

  return (
    <Tooltip title={getLayerDescription(layerType)}>
      <Typography
        component="div"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Box
          fontWeight="fontWeightBold"
          fontStyle="normal"
          px={1}
          py={0.5}
          bgcolor={editing ? layerTag.fillColor : fade(layerTag.fillColor, 0.5)}
          color={textColor}
          borderRadius={35}
          fontSize={10}>
          {layerTag.text}
        </Box>
      </Typography>
    </Tooltip>
  )
}