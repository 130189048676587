import React from "react";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = ({ firebase, history }) => {
  const onSubmit = e => {
    if (e) e.preventDefault();
    const [input] = e.target.children;
    firebase.doPasswordReset(input.value);
    alert("Sent password reset to your email");
    history.push(ROUTES.SIGN_IN);
  };

  return (
    <div>
      <h1>PasswordForgetPage</h1>
      <p>{"Under construction 👷‍"}</p>
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <input placeholder="Your email" type="text" />
        <button>Submit!</button>
      </form>
    </div>
  );
};

export default withRouter(withFirebase(PasswordForgetPage));
