import React, { PureComponent } from "react";
import { withFirebase } from "../Firebase";
import { Grid, Table, TableBody, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Header from "../Home/Header";
import voiIcon from "../../assets/companyLogos/voi.png";
import logo from "../../assets/companyLogos/logo.png";
import { Button } from "@material-ui/core";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from '../Home/SearchBar';
import UserListItemEntry from "./UserListItemEntry";
import AddUserDialog from "./AddUserDialog";
import AddNewCityDialog from "./AddNewCityDialog";
import axios from "axios";
import { GET_USERS_ACCESS, GET_CITIES_URL } from "../../constants/remotes";

class AdminPage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      company: props.match.params.companyName,
      addUserDialogIsOpen: false,
      addNewCityDialogIsOpen: false,
      accessList: [],
      searchList: [],
      cities: [],
      isAdmin: false
    };
  }

  getAccesses = async () => {
    const email = await this.props.firebase.auth.currentUser.email
    const company = this.state.company;
    const res = await axios.get(
      GET_USERS_ACCESS,
      {
        headers: {
          Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
        }
      }
    );

    let accessList = res.data.accessList.filter(user => {
      return (company in user.access)
    })
    accessList = accessList.map(user => {
      let isAdmin = false;
      if (user.access[company].includes("*SUPERUSER*")) {
        isAdmin = true;
        // TODO: this should be handled with routes
        if (user.mail === email) {
          this.setState({ isAdmin: true })
        }

      }
      return {
        mail: user.mail,
        access: user.access[company],
        isAdmin
      }
    })
    this.setState({ accessList, searchList: accessList });
  }

  async getCities() {
    const company = this.state.company;
    const res = await axios.get(
      GET_CITIES_URL,
      {
        headers: {
          Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
        }
      }
    );
    this.setState({
      cities: res.data.cityAccess[company]
    });
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getAccesses();
        this.getCities();
      }
    });
  }

  getCompanyLogo = () => {
    const company = this.state.company;
    if (company === "voi") return voiIcon;
    return logo;
  }

  goToHome = async () => {
    this.props.history.push(
      `/home/cities/${this.state.company}`
    );
  };

  setShowAddUser = (addUserDialogIsOpen) => {
    this.setState({ addUserDialogIsOpen })
  }

  searchUsers = (text) => {
    const { accessList } = this.state;
    let searchList = accessList.filter(user => {
      return user.mail.substring(0, text.length).toLowerCase() === text.toLowerCase();
    });
    this.setState({ searchList });
  }

  render() {
    const { company, searchList, cities, isAdmin, addNewCityDialogIsOpen } = this.state;
    if (!isAdmin) return null
    return (
      <React.Fragment>
        <AddNewCityDialog
          company={company}
          addNewCityDialogIsOpen={addNewCityDialogIsOpen}
          cityNames={cities}
          setShowNewCityDialog={() => { this.setState({ addNewCityDialogIsOpen: !addNewCityDialogIsOpen }) }}
        />
        <Grid container justify="center" alignItems="center" direction="column" >
          <Header companyLogo={this.getCompanyLogo()} company={company}>
            <Button
              onClick={() => this.goToHome()}
              variant="contained"
              style={{ ...styles.buttonStyle, marginRight: 12 }}
            >
              <LocationCityIcon
                style={{ fontSize: 16, color: "#2B2B2B", marginRight: 8 }}
              ></LocationCityIcon>
              Home
            </Button>
          </Header>
          <Grid style={{ marginTop: 10 }} container direction="column" justify="center" alignItems="center">
            <Typography variant="h4">Admin Panel</Typography>
            <Typography style={{ marginTop: 20, marginBottom: 10 }}>From here you can add new users and edit accesses</Typography>
          </Grid>
          <div style={styles.listDesign}>
            <div style={styles.topBar} >
              <Grid container justify="space-between" dirationc="row" alignItems="center" spacing={2}>
                <Grid item style={{ flexGrow: 1 }}>
                  <SearchBar style={{ marginTop: 0, marginBottom: 15 }} setSearch={this.searchUsers} placeholder="Search users..." hasDropDown={false} />
                </Grid>
                <Grid item>
                  <Button
                    onClick={this.setShowAddUser}
                    variant="contained"
                    style={{ ...styles.buttonStyle }}
                  >
                    <AddIcon
                      style={{ fontSize: 16, color: "#2B2B2B", marginRight: 8 }}
                    ></AddIcon>
                  Add new user
                  </Button>
                  <AddUserDialog
                    setShowAddUser={this.setShowAddUser}
                    showAddUser={this.state.addUserDialogIsOpen}
                    companyName={company}
                    refreshUsers={this.getAccesses}
                    cityList={cities}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ overflowY: "scroll" }}>
              <Table style={{ marginTop: 5 }} size="small">
                <TableBody>
                  {searchList.map(user => {
                    return (
                      <UserListItemEntry
                        key={user.mail}
                        userMail={user.mail}
                        isAdmin={user.isAdmin}
                        accessList={user.access}
                        cityList={cities}
                        companyName={company}
                        refreshUsers={this.getAccesses} />
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
          <Button
            style={{ backgroundColor: "#4a5bbb", color: "white", marginTop: 10 }}
            variant="contained"
            onClick={() => this.setState({addNewCityDialogIsOpen: true})}
          >
            <AddIcon
              style={{ fontSize: 16, color: "white", marginRight: 8 }}
            ></AddIcon>
            Add new city
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

let styles = {
  buttonStyle: {
    borderRadius: 40,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 12,
    color: "#2B2B2B",
    backgroundColor: "#F4F4F5",
    WebkitBoxShadow: "none",
    MozBoxShadow: "none",
    boxShadow: "none"
  },
  listDesign: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "800px",
    width: "65%",
    height: "60vh",
    border: "1px solid #888888",
    borderRadius: "10px",
    overflow: "hidden",
    marginBottom: "25px"
  },
  topBar: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 10px 0px 10px",
    zIndex: 1,
  }
}

export default withSnackbar(withFirebase(AdminPage));
