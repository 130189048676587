import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  Button,
  TextField
} from "@material-ui/core";
import axios from "axios";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UPDATE_ACCESS_URL } from "../../constants/remotes";
import { withFirebase } from "../Firebase";
import { withTheme } from "@material-ui/core/styles";
import CustomDialog from "../Editor/CustomDialog"
import { withSnackbar } from "notistack";

class ChangeCitiesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMail: this.props.userMail,
      companyName: this.props.companyName,
      cityList: this.props.cityList,
      chosenCities: this.props.accessList
    };
  }

  changeCityAccesses = (event, values) => {
    let chosenCities = [];
    values.forEach(city => {
      chosenCities.push(city);
    });
    this.setState({chosenCities});
  }

  changeCities = async () => {
    const {userMail, chosenCities, companyName} = this.state;
    try{
      const res = await axios.post(
        UPDATE_ACCESS_URL,
        {userMail, chosenCities, companyName},
        {
          headers: {
            Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
          }
        }
      );
      if(res.status === 200){
        this.props.enqueueSnackbar("Successfully changed city-access of " + userMail, { 
          variant: "success",
          autoHideDuration: 4000
        });
      }else if(res.status === 401){
        this.props.enqueueSnackbar("Not authorized to change cities", { 
          variant: "error",
          autoHideDuration: 4000
        });
      }
    }catch(error){
      this.props.enqueueSnackbar("Something went wrong, try again", {
        variant: "error",
        autoHideDuration: 4000
      });
      console.log(error)
    }
  }

  renderDialogContent = () => {
    const {cityList} = this.props;
    let cityNames = [];
    cityList.forEach(city => {
      cityNames.push(city.name);
    })
    const {chosenCities} = this.state;
    return (
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <Autocomplete
          multiple
          style={{marginBottom: 16}}
          id="tags-standard"
          options={cityNames}
          value={chosenCities}
          onChange={this.changeCityAccesses}
          getOptionLabel={(name) => name.replace(/^./, name[0].toUpperCase())}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accesses"
              placeholder="Cities" />
          )}>
        </Autocomplete>
      </DialogContent>
    );
  }

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          style={{ backgroundColor: "#4a5bbb", color: "white" }}
          variant="contained"
          onClick={() => this.changeCities()}
        >
        Confirm change
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { showChangeCities, setShowChangeCities } = this.props;
    return (
      <CustomDialog
        open={showChangeCities}
        onClose={setShowChangeCities}
        subTitle={"Change which cities the given user has access to"}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Change cities"}
      />
    );
  }
}

export default withTheme(withFirebase(withSnackbar(ChangeCitiesDialog)));