import { Box, Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, List, ListItem, ListItemText, TextField } from "@material-ui/core"
import CustomDialog from "./CustomDialog"
import React, { useEffect } from "react"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import { FETCH_MDS_POLICIES } from "../../constants/remotes"
import { combineLayers } from "../../utils/geo/operations"

const CustomAccordion = ({ geographies, handleCheckBoxChange, setSelectedForDownload, selectedForDownload }) => {
  const [expanded, setExpanded] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [checkall, setCheckall] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function reduceId(id) {
    return id.slice(0, 4);
  }

  function reduceName(name) {
    return name.slice(0, 16) + "...";
  }

  function displayDate(str) {
    const date = new Date(str);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthNames[date.getMonth()] + " " + date.getUTCDate() + ", " + date.getUTCFullYear();
  }

  const Details = ({ geo, type }) => {
    return (
      <Box>
        <h3 style={{ textAlign: "center" }}>{type === "policy" ? "Policy" : "Rule"} details</h3>
        <List style={{ backgroundColor: "#fcfcfc" }}>
          {Object.keys(geo[type]).map((detail => {
            return <ListItem key={detail}>
              <ListItemText
                primary={`${detail}: ${geo[type][detail]}`}
              />
            </ListItem>
          }))}
        </List>
      </Box>
    )
  }

  function filterGeosOnSearch() {
    return geographies.filter(geo =>
      geo.rule.name.toLowerCase()
        .includes(search.toLowerCase()));
  }

  function handleCheckAll() {
    const currentIds = filterGeosOnSearch().map(geo => geo.id);
    if (checkall) {
      setSelectedForDownload(currentIds.filter(id => !selectedForDownload.includes(id)))
    } else {
      setSelectedForDownload(currentIds.concat(selectedForDownload))
    }
    setCheckall(!checkall)
  }

  const DataDescription = () => {
    return (
      <div
        style={{width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5, height: 50, backgroundColor: "#e0e0e0", display: "flex", justifyContent: "space-between", alignItems: "center"}}
      >
        <div style={{width: "100px", marginLeft: 17}}>Name</div>
        <div style={{width: "100px"}}>Last updated</div>
        <Checkbox 
          color="primary"
          style={{marginRight: 68}}
          onChange={handleCheckAll}
          checked={checkall}
        ></Checkbox>
        
      </div>)
  }

    

  return (
    <div>
      <TextField
        label="Search..."
        variant="outlined"
        style={{width: "100%", marginBottom: "10px"}}
        onChange={e => setSearch(e.currentTarget.value)}
      />
      <DataDescription/>
      {filterGeosOnSearch().map((geo, index) =>
        <Accordion
          key={index}
          expanded={index === expanded}
          onChange={handleChange(index)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div style={{ width: "33%" }}>

                {reduceName(geo.rule.name) + " " + reduceId(geo.id)}
              </div>

              <div style={{ width: "33%" }}>
                {displayDate(geo.policy.published_date)}
              </div>

              <FormControlLabel
                id={geo.id}
                checked={selectedForDownload.includes(geo.id)}
                onClick={(event) => handleCheckBoxChange(event, geo.id)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox color="primary" />}
              ></FormControlLabel>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{ width: "100%"}}>
              <h4 style={{ textAlign: "center", marginTop: 20 }}>{`${geo.id}`}</h4>
              <Details
                geo={geo}
                type={"policy"}
              />
              <Details
                geo={geo}
                type={"rule"}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )
}

export default function MDSViewer({ cityName, downloadData, isOpen, setDisplayMDSOverview }) {
  const [geographies, setGeographies] = React.useState([]);
  const [policies, setPolicies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [selectedForDownload, setSelectedForDownload] = React.useState([]);

  const handleCheckBoxChange = (event, id) => {
    if (event) {
      event.stopPropagation();
    }
    if (selectedForDownload.includes(id)) {
      setSelectedForDownload(selectedForDownload.filter(x => x !== id));
    } else {
      setSelectedForDownload([...selectedForDownload, id])
    }
  }

  async function downloadGeographies() {

    async function downloadGeography(id) {
      const res = await axios.get(getGeographyURL(id))
      // TODO: fix that backend should only have geojson
      return res.data;
    }
    const geographies = await Promise.all(
      selectedForDownload.map((id) => downloadGeography(id))
    );
    const merged = combineLayers(geographies);
    downloadData(merged, "geographies.geojson", false);
  }

    

  useEffect(() => {
    async function fetch_mds_policies() {
      const res = await axios.get(FETCH_MDS_POLICIES, {
        params: { cityName },
      });
      const activeIds = res.data.active
      const activePolicies = res.data.policies.filter(p => activeIds.includes(p.policy_id))
      setPolicies(activePolicies);
    }
    fetch_mds_policies();
  }, [cityName])

  useEffect(() => {
    function omit(obj, props) {
      props = props instanceof Array ? props : [props]
      // eslint-disable-next-line
            return eval(`(({${props.join(',')}, ...o}) => o)(obj)`)
    }
    if (policies.length) {
      console.log("transforming data");
      let geos = [];
      policies.forEach((policy) => {
        policy.rules.forEach((rule) => {
          rule.geographies.forEach((geo) => {
            const obj = {};
            obj['id'] = geo;
            obj['rule'] = omit(rule, ['geographies'])
            obj['policy'] = omit(policy, ['rules'])
            geos.push(obj);
          })
        })
      })
      geos = geos.sort((a, b) => a.rule.name.localeCompare(b.rule.name))
      setGeographies(geos)
      setLoading(false)
    }
  }, [policies])

  function getGeographyURL(id) {
    return `https://geowiz-prod.appspot.com.storage.googleapis.com/MDS/${cityName}/${id}.geojson`
  }

  function renderDialogActions() {
    return (
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={downloadGeographies}
          disabled={selectedForDownload.length === 0}
        >
          {selectedForDownload.length ? `Download (${selectedForDownload.length})` : "Download"}
        </Button>
      </DialogActions>
    )
  }


  return (
    <CustomDialog
      open={isOpen}
      onClose={() => {
        setSelectedForDownload([])
        setDisplayMDSOverview(false)}}
      dialogContent={
        loading ? (
          <div style={{display: "flex", justifyContent:"center"}}>
            <CircularProgress/>
          </div>
        ) :
          <CustomAccordion 
            geographies={geographies} 
            handleCheckBoxChange={handleCheckBoxChange} 
            selectedForDownload={selectedForDownload}
            setSelectedForDownload={setSelectedForDownload}
          />}
      renderDialogActions={renderDialogActions}
      title={"MDS overview"}
    />
  )
} 