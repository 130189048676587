import * as jsts from "jsts";
import { getType, point, booleanEqual, booleanPointInPolygon, booleanContains, getCoords, area, buffer, difference, featureCollection, multiPolygon, getGeom, featureEach, polygon, feature, bbox, simplify, truncate, distance } from "@turf/turf";

function union(curData, addData) {
  const reader = new jsts.io.GeoJSONReader();
  const polys = getGeosBoundless(curData).Polygons.concat(getGeosBoundless(addData).Polygons);
  const jstsPolys = polys.map((pol) => reader.read(getGeom(pol)).buffer(0));
  const factory = new jsts.geom.GeometryFactory();
  let collection = factory.createGeometryCollection(jstsPolys);
  const union = jsts.operation.union.UnaryUnionOp.union(collection);
  const writer = new jsts.io.GeoJSONWriter();
  return writer.write(union);
}

function unionArray(polys) {
  const reader = new jsts.io.GeoJSONReader();
  const jstsPolys = polys.map((pol) => reader.read(getGeom(pol)).buffer(0));
  const factory = new jsts.geom.GeometryFactory();
  let collection = factory.createGeometryCollection(jstsPolys);
  const union = jsts.operation.union.UnaryUnionOp.union(collection);
  const writer = new jsts.io.GeoJSONWriter();
  return writer.write(union);
}

const unionToFc = (...collections) => featureCollection([feature(union(...collections))]);

function subtract(curData, subData) {
  const curMulti = createMultipolygonFromArr(getGeosBoundless(curData).Polygons);
  const subMulti = createMultipolygonFromArr(getGeosBoundless(subData).Polygons);
  try {
    let diff = difference(curMulti, subMulti);
    if (!diff) diff = createFc([]);
    return diff;
  } catch (error) {
    console.log(error);
    console.log("Could not subtract");
  }
}

function combineLayers(featureCollections) {
  let polygons = [].concat.apply([], featureCollections.map(fc => getGeosBoundless(fc).Polygons));
  polygons = polygons.map(pol => truncate(pol, {precision: 8, coordinates: 2}));
  polygons = unionArray(polygons);
  polygons = getGeosBoundless(polygons).Polygons
  return featureCollection(polygons);
}

function getFeatureToFill(pols, coords) {
  const pt = point(coords);
  let holes = getHolesOfPols(pols);
  const overlappingHoles = holes.filter(hole => booleanPointInPolygon(pt, hole));
  if (overlappingHoles.length === 0) throw new Error('No Holes Selected. Try to hide some layers');
  const minHole = holes.length > 1 ? overlappingHoles.reduce((prev, cur) => (area(prev) < area(cur) ? prev : cur)) : holes[0];
  let polsWithHoleRemoved = union(featureCollection(pols), featureCollection([minHole]));
  polsWithHoleRemoved = getGeosBoundless(polsWithHoleRemoved).Polygons;
  // remove minHole from all holes
  holes = holes.filter(hole => !booleanEqual(hole, minHole));
  // subtract holes contained in minhole from 'pols'
  let containedHoles = holes.filter(hole => booleanContains(minHole, hole));
  containedHoles = createMultipolygonFromArr(containedHoles);
  polsWithHoleRemoved = unionArray(polsWithHoleRemoved);
  let subtraction = subtract(polsWithHoleRemoved, containedHoles);
  let subtractedPols = getGeosBoundless(subtraction).Polygons;
  // add polygons contained in minhole to 'pols'
  let polsWithin = pols.filter(pol => booleanContains(minHole, pol));
  let result = [];
  result = result.concat(polsWithin, subtractedPols);
  result = unionArray(result);
  result = createMultipolygonFromArr(getGeosBoundless(result).Polygons);
  result = featureCollection([result]);
  result = subtract(result, featureCollection(pols));
  result = getGeosBoundless(result).Polygons;
  result = result.filter(pol => area(pol) > 10); // some funky shit appears if we don't filter them off.
  result = buffPolygons(result, 0.1); // buff it with 0.1 meter to avoid small lines
  result = simplifyPolygons(result, 0.00001);
  return featureCollection(result);
}

function getHolesOfPols(pols) {
  const holes = [];
  pols.forEach((pol) => {
    const coords = getCoords(pol);
    if (coords.length > 1) // has at least 1 hole
      for (let i = 1; i < coords.length; i++) {
        holes.push(polygon([coords[i]]));
      }

  })
  return holes;
}

const createFc = data => featureCollection(data);

function getValidatedMultiPolygon(geojson) {
  let polygons = getGeosBoundless(geojson).Polygons;
  return createMultipolygonFromArr(polygons);
}

export function convertMultiPolygonToFeatureCollection(geojson) {
  const features = geojson.coordinates.map(coords => createFeatureFromCoordinates(coords, 'Polygon'))

  return {
    type: 'FeatureCollection',
    features,
  }
}

export function convertPolygonToFeatureCollection(geojson) {
  const feature = createFeatureFromCoordinates(geojson.coordinates, 'Polygon')

  return {
    type: 'FeatureCollection',
    features: [feature],
  }
}

function createFeatureFromCoordinates(coordinates, type) {
  return {
    type: 'Feature',
    geometry: {
      type,
      coordinates,
    },
    properties: {},
  }
}

const createZones = (base, add, sub) => {
  if (!base) base = createFc([]);
  if (!add) add = createFc([]);
  if (!sub) sub = createFc([]);
  return { base, add, sub }
}

function isValidFeatureCollection(data) {
  if (getType(data) !== "FeatureCollection") data = featureCollection([data]);
  try {
    let hasFeature = data.features.length > 0;
    if (data.features[0].type === "GeometryCollection" && data.features[0].geometries.length === 0) {
      hasFeature = false;
    }
    return hasFeature;
  } catch (error) {
    return false;
  }
}


const getGeosDict = () => ({
  Polygons: [],
  LineStrings: [],
  Points: [],
  MultiPoints: [],
  MultiLineStrings: [],
  MultiPolygons: []
});

function salvageHoleIssue(coord) {
  let salvagedPol = null;
  let holeIssues = []
  if (coord.length > 1) {
    for (let i = 1; i < coord.length; i++) {
      if (coord[i].length < 4) {
        /*
        Less than 4 means that it cannot be a complete polygon. In this case, there has somehow been a mistake and we can discard the incomplete holes.
        */
        holeIssues.push(i);
      }
    }}


  // removing all holes that have issues from the original coord array
  if (holeIssues.length > 0) {
    const newCoords = [];
    coord.forEach((obj, idx) => {
      if (!holeIssues.includes(idx))
        newCoords.push(obj)
    })
    // try actually creating the polygon object from the new coord object
    try {
      salvagedPol = polygon(newCoords)
    } catch (error) {
      console.log(error);
    }

  }

  return salvagedPol;
}

function splitMultiPolygon(feature) {
  const polygons = []
  const coords = getCoords(feature)

  coords.forEach(coord => {
    try {
      polygons.push(polygon(coord));
    } catch (error) {
      // // try to salvage polygon if the issue is with a hole of the polygon
      const salvagedPol = salvageHoleIssue(coord);
      if (salvagedPol) {
        // TODO: we should warn the user that the geojson object was slightly altered
        polygons.push(salvagedPol)
      } else {
        console.log("Issue with coord", coord);
      }
    }
  });
  return polygons;
}

// FIXME: Refactor and remove
function getGeosBoundless(data) {
  const geos = getGeosDict();
  let type;
  let isGeojson = true;
  try {
    type = getType(data);
  } catch (error) {
    isGeojson = false
  }
  if (type !== "FeatureCollection" && isGeojson) data = featureCollection([data]);
  featureEach(data, feature => {
    let type = getType(feature);
    try {
      if (type === "GeometryCollection") {
        feature.geometry.geometries.forEach(geo =>
          geos[getType(geo) + "s"].push(geo)
        );
      } else if (type === "MultiPolygon") {
        geos.Polygons = geos.Polygons.concat(splitMultiPolygon(feature));
      } else {
        geos[type + "s"].push(feature);
      }
    } catch (e) {
      console.log("A problem occured with feature: ", feature);
    }
  });
  return geos;
}

function optimizeFeatures(fc) {
  const MIN_AREA = 10;
  try {
    let pols = getGeosBoundless(fc).Polygons;
    pols = removeInteriorRingsSmallerThan(pols, MIN_AREA);
    pols = simplifyPolygons(pols, 0.000005);
    return unionArray(pols);
  } catch (error) {
    console.log("Did not optimize features", error);
    return fc;
  }
}

function buffPolygons(polygons, ratio) {
  if (!polygons.length) {
    polygons = getGeosBoundless(polygons).Polygons
  }
  const buffed = [];
  const ignored = [];
  polygons.forEach((pol) => {
    let buffed_pol = buffer(pol, ratio, {
      units: "meters"
    });

    if (buffed_pol) buffed.push(buffed_pol);
    else {
      ignored.push(pol);
    }
  })
  if (ignored.length > 0) {
    console.log("Ignored " + ignored.length + " polygons");
  }
  return buffed;
}

const createMultipolygonFromArr = arr => multiPolygon(arr.map(poly => getCoords(poly)));

function removeSmallHoles(pols) {
  try {
    const newPolygons = [];
    const polygons = getGeosBoundless(pols).Polygons;
    for (const pol of polygons) {
      const newPol = removeSmallHoleFromPolygon(pol);
      newPolygons.push(newPol);
    }
    return featureCollection(newPolygons);
  } catch (error) {
    return pols;
  }
}

function removeSmallHoleFromPolygon(pol) {
  const coordinates = getCoords(pol);
  const hasHoles = coordinates.length > 1;
  if (hasHoles) {
    const newCoords = [];
    newCoords.push(coordinates[0]);
    for (let i = 1; i < coordinates.length; i++) {
      const ring = polygon([coordinates[i]]);
      const a = area(ring);
      if (a > 50) {
        newCoords.push(coordinates[i]);
      }
    }
    pol.geometry.coordinates = newCoords;
  }
  return fixPolygon(pol);
}

function fixPolygon(pol) {
  const coords = getCoords(pol);
  return polygon(coords);
}

function filterPolygons(polygons, bounds) {
  // removesmallpolygons
  const MIN_AREA = 2000;
  const MIN_AREA_BRIDGE = 100
  polygons = polygons.filter((pol) => {
    if (pol.properties.man_made === "bridge" || pol.properties.highway === "pedestrian" ) {
      return area(pol) > MIN_AREA_BRIDGE;
    }
    return area(pol) > MIN_AREA
  })
  // simplify
  polygons = simplifyPolygons(polygons, 0.00001);
  // intersection
  const multi = getIntersection(polygons, bounds)
  polygons = getCoords(multi).map(coord => polygon(coord));


  //removeinteriorrings
  polygons = removeInteriorRingsSmallerThan(polygons, MIN_AREA);
  return polygons;
}

function getLineStringLength(ls) {
  let coords = getCoords(ls);
  coords = coords.reduce((acc, cur, i, arr) => {
    if (arr[i + 1]) {
      return acc + distance(cur, arr[i + 1], { units: "meters" })
    }
    else return acc
  }, 0)
  return coords;
}

function filterFeatureCollection(fc, filter) {
  let polygons = getGeosBoundless(fc).Polygons;
  polygons = polygons.filter(filter);
  return featureCollection(polygons);
}

export function getPolygonsWithinCoords(data, coords){
  let polygons = getGeosBoundless(data).Polygons.filter(pol => booleanPointInPolygon(point(coords), pol));
  polygons = polygons.map(pol => polygon(getCoords(pol)));
  return polygons.length > 0 ? featureCollection(polygons) : null
}


export function filterPolygonsLargerThan(data, minArea) {
  return filterFeatureCollection(data, pol => area(pol) > minArea)
}

export function filterPolygonsSmallerThan(data, maxArea) {
  return filterFeatureCollection(data, pol => area(pol) <= maxArea)
}

function buffPolygon(pol, ratio) {
  if (!ratio)
    return pol;
  try {
    const buffedPol = buffer(pol, ratio, { units: "meters" });
    if (!buffedPol) return pol;
    else return buffedPol;
  } catch (error) {
    return pol;
  }
}

const validateGeoJson = geoJson => {
  const polygons = getGeosBoundless(geoJson).Polygons;
  if (polygons.length === 0) return false;
  const multiPolygon = createMultipolygonFromArr(polygons);
  const geom = getGeom(multiPolygon);
  try {
    let jsts = readToJSTS(geom);
    jsts = jsts.buffer(0);
    return jsts.isValid();
  } catch (e) {
    return false;
  }
}

const getBbox = data => {
  const coords = bbox(data); // buffing 100 meters
  return `${coords[1]},${coords[0]},${coords[3]},${coords[2]}`;
}

const getIntersection = (data, bounds) => {
  const multiA = createMultipolygonFromArr(data);
  const multiB = getValidatedMultiPolygon(bounds);
  const jstsA = getValidJSTSGeo(readToJSTS(getGeom(multiA)));
  const jstsB = getValidJSTSGeo(readToJSTS(getGeom(multiB)));
  let intersection = feature(writeToGEO(jstsA.intersection(jstsB)));
  if (getType(intersection) !== "MultiPolygon") {
    intersection = createMultipolygonFromArr(
      getGeosBoundless(featureCollection([intersection])).Polygons
    ); // if not MultiPolygon yet, convert it.
  }
  return intersection;
};

const getValidJSTSGeo = jstsGeo =>
  jstsGeo.isValid() ? jstsGeo : jstsGeo.buffer(0);

const writeToGEO = _jsts => new jsts.io.GeoJSONWriter().write(_jsts);
const readToJSTS = _geom => new jsts.io.GeoJSONReader().read(_geom);

function simplifyPolygons(polygons, tol) {
  if (!tol) tol = 0.00001;
  return polygons.map((pol) => {
    if (area(pol) > 50000) return pol;
    return simplify(pol, { tolerance: tol, highQuality: false })
  });
}

function removeInteriorRingsSmallerThan(polygons, limit) {
  polygons.forEach(pol => {
    const coordinates = getCoords(pol);
    if (coordinates.length > 1) {
      const newCoords = [];
      newCoords.push(coordinates[0]);
      for (let i = 1; i < coordinates.length; i++) {
        const ring = polygon([coordinates[i]]);
        if (area(ring) > limit) {
          newCoords.push(coordinates[i]);
        }
      }
      pol.geometry.coordinates = newCoords;
    }
  });
  return polygons;
}

function prepareKMLdata(data) {
  let pols = getGeosBoundless(data).Polygons;
  return featureCollection(pols);
}
function containsPoints(data) {
  let points = getGeosBoundless(data).Points;
  return points.length > 0;
}
function convertPoints(data) {
  let pols = getGeosBoundless(data).Polygons;
  let pointsPol = getGeosBoundless(data).Points.map(point => buffPolygon(point, 0.01))
  let combined = pols.concat(pointsPol);

  return featureCollection(combined);
}

const clone = object => JSON.parse(JSON.stringify(object));

// Given an array of MultiPolygons, will merge them into one MulitPolygon
function mergeMultiPolygons(multiPolygons) {
  return multiPolygons.reduce((acc, polygon) => {
    polygon.coordinates.forEach(coords => {
      acc.coordinates.push(coords)
    })

    return acc
  }, { type: "MultiPolygon", coordinates: [] })
}

export { prepareKMLdata, containsPoints, convertPoints, simplifyPolygons, getLineStringLength, optimizeFeatures, removeSmallHoles, getFeatureToFill, buffPolygon, getBbox, buffPolygons, filterPolygons, isValidFeatureCollection, union, subtract, combineLayers, createFc, getValidatedMultiPolygon, createZones, validateGeoJson, unionToFc, getGeosBoundless, clone, mergeMultiPolygons };
