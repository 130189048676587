import React, { Component } from 'react';
import Editor from '../Editor';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import SignOutPage from '../SignOut';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AdminPage from '../AdminPanel';
import AuthenticationOverlay from '../Authentication';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import * as analytics from '../../analytics';
import { isMobile } from 'react-device-detect';
import LicensePage from '../Licenses';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.setState({ authUser });
        analytics.identify(authUser.uid, authUser.email);
      } else {
        this.setState({ authUser: null });
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    if (isMobile) {
      return (
        <div style={{ textAlign: 'center' }}>
          This content is unavailable on mobile. <a href='https://geowiz.tech'> Go back</a>
        </div>
      );
    } else
      return (
        <Router>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.SIGN_OUT} component={SignOutPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.PLAYGROUND_EDITOR} component={() => <Editor isPlayground />} />
          <Route path={ROUTES.PLAYGROUND_DEMO} component={() => <Editor isPlayground isDemo />} />
          <Route path={ROUTES.LICENSES} component={LicensePage} />

          {/* Authenticated pages */}
          <Route path={ROUTES.HOME} component={AuthenticationOverlay} />
          <Route path={ROUTES.HOME_CITIES} component={HomePage} />
          <Route exact path={ROUTES.HOME_CITIES_REDIRECT}>
            <Redirect to={ROUTES.SIGN_IN} />
          </Route>
          <Route path={ROUTES.ADMIN_PANEL} component={AdminPage} />
          <Route path={ROUTES.EDITOR} component={Editor} />
        </Router>
      );
  }
}

export default withFirebase(App);
