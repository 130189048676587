import React, { PureComponent } from "react";
import { withTheme } from "@material-ui/core/styles";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { AccountBalance, PanTool } from "@material-ui/icons";
import addZoneIcon from "../../assets/icons/addZone.svg";
import addRect from "../../assets/icons/addRect.png";
import addCircle from "../../assets/icons/addCircle.svg";
import subRect from "../../assets/icons/subRect.png";
import subCircle from "../../assets/icons/subCircle.svg";
import removeZoneIcon from "../../assets/icons/removeZone.svg";
import OSMIcon from "../../assets/icons/osm.png";
import voiIcon from "../../assets/companyLogos/voi.png";
import logo from "../../assets/brand/SVG/logo.svg";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { getLayerFillColor } from "./LayerStyles";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import LayerTag from "./LayerTag";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LayersIcon from '@material-ui/icons/Layers';
import BackupIcon from "@material-ui/icons/Backup";
import { withSnackbar } from "notistack";
import { LAYER_TYPE_OPS } from "./Map";
import "./ToolbarCustomScrollbar.css";

const styles = {
  root: {
    flexDirection: "column"
  },
  grouped: {
    border: "none",
    color: "#4b4b4b",
    borderRadius: 0,
    "&$selected": {
      borderLeft: "3px solid #4a5bbb",
      color: "#4a5bbb",
      filter:
        "invert(29%) sepia(97%) saturate(712%) hue-rotate(203deg) brightness(94%) contrast(87%)" // hack for changing color of images
    }
  },
  selected: {},
  icon: {
    width: 24,
  },
  imageIcon: {
    width: 20
  }
};

class Toolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      bucketColor: null,
      subTypesOpen: false,
      addTypesOpen: false,
      addType: "Polygon", // this is so shit, it has to be capitalized, i don't want to fix it right now
      subType: "Polygon",
      shape: "polygon"
    };
  }

  onBucketClick = e => {
    this.props.setBucketOpen(true);
    this.setState({ anchorEl: e.currentTarget });
  };

  onTypesClick = (e, type) => {
    e.preventDefault();
    if (type === "add")
      this.setState({ anchorEl: e.currentTarget, addTypesOpen: true });
    else this.setState({ anchorEl: e.currentTarget, subTypesOpen: true });
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.setBucketOpen(false);
    this.setState({
      addTypesOpen: false,
      subTypesOpen: false,
      anchorEl: null
    });
  };

  onBucketLayerClick = (event, layer) => {
    event.stopPropagation();
    this.props.setLayerToBeEdited(layer.id);
    this.props.setBucketOpen(false);
    this.setState(
      () => {
        this.props.updatePaintBucket(); // have to wait since this method has to use layerToBeEdited
      }
    );
  };

  getBucketColor = layerType => {
    const color = getLayerFillColor(layerType);
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  };

  changeEditMode = event => {
    console.log(event);
    const editMode = event.currentTarget.value;
    this.props.changeEditMode(event, editMode);
    if (editMode !== "paintBucket") this.setState({ bucketColor: null }); // set bucket color to default
  };

  renderDrawingItemImg = (shape, type) => {
    switch (shape) {
    case "Polygon":
      if (type === "add") {
        return (<img alt="add" src={addZoneIcon} style={styles.imageIcon} />)
      } else {
        return (<img alt="sub" src={removeZoneIcon} style={styles.imageIcon} />)
      }
    case "Circle":
      if (type === "add") {
        return (<img alt="add" src={addCircle} style={styles.imageIcon} />)
      } else {
        return (<img alt="sub" src={subCircle} style={styles.imageIcon} />)
      } 
    case "Rectangle":
      if (type === "add") {
        return (<img alt="add" src={addRect} style={styles.imageIcon} />)
      } else {
        return (<img alt="sub" src={subRect} style={styles.imageIcon} />)
      }
    default:
      return <img alt="add" src={addRect} style={styles.imageIcon} />
    }
  }

  render() {
    const { classes, bucketOpen, bucketMenuPos, shouldDisplayMDS } = this.props;

    return (
      <div
        className="hideInSS customScrollbar"
        style={{
          zIndex: 9,
          position: "absolute",
          left: 0,
          width: 65,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          background: "white",
          boxShadow:
            "0 12px 24px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)",
          overflowY: "auto", 
          overflowX: "hidden"
        }}
      >
        <div style={{ marginBottom: 35 }}>
          <img
            alt="companyName"
            src={this.props.companyName === "voi" ? voiIcon : logo}
            style={{
              width: "35px",
              height: "auto",
              marginTop: 30,
              marginBottom: 20,
              marginLeft: this.props.companyName === "voi" ? 12 : 13
            }}
          ></img>
          <ToggleButtonGroup
            style={{ display: "flex", flexDirection: "column" }}
            value={this.props.editMode}
            exclusive
            onChange={this.changeEditMode}
            className='tools'
            classes={{
              root: classes.root,
              grouped: classes.grouped
            }}
          >
            <ToggleButton value="view" classes={{ selected: classes.selected }} style={{ height: 45 }}>
              <Tooltip title="(P)an" placement="right">
                <PanTool style={styles.icon}/>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              onContextMenu={e => this.onTypesClick(e, "add")}
              value={"add" + this.state.addType}
              classes={{ selected: classes.selected }}
              style={{ height: 45 }}
            >
              <Tooltip title="(A)dd a new zone" placement="right">
                {this.renderDrawingItemImg(this.state.addType, "add")}
              </Tooltip>
              <Menu
                open={this.state.addTypesOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <MenuItem
                  key={"add"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ addType: "Polygon" });
                    this.props.changeEditMode(e, "add");
                    this.props.setAddTypeShape("Polygon")
                    this.handleClose(e);
                  }}
                >
                  {this.renderDrawingItemImg("Polygon", "add")}
                </MenuItem>
                <MenuItem
                  key={"addRectangle"}
                  value={"addRectangle"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ addType: "Rectangle" });
                    this.props.changeEditMode(e, "addRectangle");
                    this.props.setAddTypeShape("Rectangle")
                    this.handleClose(e);
                  }}
                >
                  {this.renderDrawingItemImg("Rectangle", "add")}
                </MenuItem>
                <MenuItem
                  key={"addCircle"}
                  value={"addCircle"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ addType: "Circle" });
                    this.props.changeEditMode(e, "addCircle");
                    this.props.setAddTypeShape("Circle")
                    this.handleClose(e);
                  }}>
                  {this.renderDrawingItemImg("Circle", "add")}
                </MenuItem>
              </Menu>
            </ToggleButton>

            <ToggleButton
              onContextMenu={e => this.onTypesClick(e, "sub")}
              value={"sub" + this.state.subType}
              classes={{ selected: classes.selected }}
              style={{ height: 45 }}
            >
              <Tooltip title="(R)emove a zone" placement="right">
                {this.renderDrawingItemImg(this.state.subType, "sub")}
              </Tooltip>
              <Menu
                open={this.state.subTypesOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <MenuItem
                  key={"sub"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ subType: "Polygon" });
                    this.props.changeEditMode(e, "subPolygon");
                    this.props.setSubTypeShape("Polygon")
                    this.handleClose(e);
                  }}
                >
                  <img
                    alt="remove"
                    src={removeZoneIcon}
                    style={styles.imageIcon}
                  ></img>
                </MenuItem>
                <MenuItem
                  key={"subRectangle"}
                  value={"subRectangle"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ subType: "Rectangle" });
                    this.props.changeEditMode(e, "subRectangle");
                    this.props.setSubTypeShape("Rectangle")
                    this.handleClose(e);
                  }}
                >
                  <img alt="remove" src={subRect} style={styles.imageIcon} />
                </MenuItem>
                <MenuItem
                  key={"subCircle"}
                  value={"subCircle"}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ subType: "Circle" });
                    this.props.changeEditMode(e, "subCircle");
                    this.props.setSubTypeShape("Circle")
                    this.handleClose(e);
                  }}
                >
                  <img alt="remove" src={subCircle} style={styles.imageIcon} />
                </MenuItem>
              </Menu>
            </ToggleButton>
            <ToggleButton
              value="paintBucket"
              classes={{ selected: classes.selected }}
              onClick={this.onBucketClick}
              style={{ height: 45 }}
            >
              <Tooltip title="(F)ill in zones" placement="right">
                <FormatColorFillIcon
                  fontSize="large"
                  htmlColor={this.state.bucketColor}
                  style={styles.icon}
                ></FormatColorFillIcon>
              </Tooltip>
              <Menu
                open={bucketOpen}
                anchorEl={this.state.anchorEl}
                anchorPosition={{ left: bucketMenuPos.x, top: bucketMenuPos.y - 103 }} // Menu is placed ~100px under by default for some reason
                anchorReference={this.state.anchorEl == null ? 'anchorPosition' : 'anchorEl'}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                {this.props.layers.map(layer => (
                  <MenuItem
                    key={layer.layerName}
                    value={layer.layerName}
                    onClick={e => this.onBucketLayerClick(e, layer)}
                  >
                    <LayerTag layerType={layer.layerType}></LayerTag>
                    <Typography style={{ marginLeft: 10 }}>
                      {" "}
                      {layer.layerName}{" "}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </ToggleButton>

            <ToggleButton
              value="modify"
              classes={{ selected: classes.selected }}
              style={{ height: 45 }}
            >
              <Tooltip title="(M)odify a zone" placement="right">
                <EditIcon style={styles.icon}  />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="osmPicker"
              classes={{ selected: classes.selected }}
              style={{ height: 45 }}
              onClick={event => {
                if (
                  this.props.layers.filter(layer => layer.editing).length === 0
                ) {
                  event.preventDefault();
                  this.props.enqueueSnackbar(
                    "Must have at least one layer selected",
                    { variant: "error", autoHideDuration: 2000 }
                  );
                } else if (
                  this.props.layers.filter(
                    layer => layer.layerType === LAYER_TYPE_OPS
                  ).length === 0
                ) {
                  event.preventDefault();
                  this.props.enqueueSnackbar(
                    "OSM Requires an operational area",
                    { variant: "error", autoHideDuration: 2000 }
                  );
                }
              }}
            >
              <Tooltip title="Fetch and insert zones from OpenStreetMaps" placement="right" >
                <img alt="osm" src={OSMIcon} style={styles.icon}></img>
              </Tooltip>
            </ToggleButton>
            <Button
              style={{ height: 45, color: "#4b4b4b" }}
              onClick={() => this.props.setCalculateDifferenceDialogOpen(true)}>
              <Tooltip title="Calculate available parking area" placement="right">
                <LayersIcon
                  style={styles.icon} />
              </Tooltip>
            </Button>
          </ToggleButtonGroup>
          {shouldDisplayMDS ? <Button
            style={{ height: 45, color: "#4b4b4b" }}
            onClick={() => this.props.setDisplayMDSOverview(true)}>
            <Tooltip title="Open MDS overview" placement="right">
              <AccountBalance
                style={styles.icon} />
            </Tooltip>
          </Button> : null}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            style={{ marginBottom: 5, height: 45, minWidth: 0 }}
            onClick={() => this.props.setShowUploadDialog(true)}
          >
            <BackupIcon style={{...styles.icon, color: "#4b4b4b" }} />
          </Button>
          <Button
            disabled={this.props.isPlayground}
            style={{ marginBottom: 10, height: 45, minWidth: 0 }}
            onClick={() => {
              if (window.confirm('Are you sure you want to exit? You might have unsaved changes')) 
                this.props.onCitiesClick();
            }
            }
          >
            <LocationCityIcon
              style={{
                ...styles.icon,
                color: this.props.isPlayground ? null : "#4b4b4b"
              }}
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(withTheme(Toolbar)));
