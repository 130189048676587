import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  Button,
  Checkbox,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withTheme } from "@material-ui/core/styles";
import { getLayerDescription, getLayerType } from "./LayerStyles";
import LayerTag from "./LayerTag";
import CustomDialog from "./CustomDialog"

class DownloadOnMergeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layersToDownload: [],
      amountOfFilesToDownload: 0,
      exportAsKml: false
    };
  }

  getLayerTypes = () => {
    let layerTypes = this.props.layers.map(layer =>
      getLayerDescription(layer.layerType)
    );
    layerTypes = [...new Set(layerTypes)];
    return layerTypes;
  };

  getLayersOfLayerType = layerType => {
    const { layers } = this.props;
    return layers.filter(layer => layer.layerType === layerType);
  };

  getDownloadDict = () => {
    const { layersToDownload } = this.state;
    let dict = {};
    this.getLayerTypes().forEach(type => {
      dict[type] = [];
      layersToDownload.forEach(layerName => {
        if (
          this.props.getLayerFromName(layerName).layerType ===
          getLayerType(type)
        )
          dict[type].push(layerName);
      });
    });
    return dict;
  };

  updateAmountOfFilesToDownload = () => {
    let count = 0;
    const dict = this.getDownloadDict();
    Object.keys(dict).forEach(type => {
      if (dict[type].length > 0) count++;
    });
    this.setState({ amountOfFilesToDownload: count });
  };

  renderDialogContent = () => {
    const { layersToDownload } = this.state;
    return (
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {this.getLayerTypes().map(layerType => (
          <FormControl component="fieldset" key={layerType}>
            <div
              style={{
                display: "flex"
              }}
            >
              <LayerTag editing layerType={getLayerType(layerType)}></LayerTag>
              <FormLabel
                component="legend"
                style={{ marginLeft: 10, marginTop: 5 }}
              >
                {layerType}
              </FormLabel>
            </div>
            {this.getLayersOfLayerType(getLayerType(layerType)).filter(x => !x.hidden).map(layer => (
              <FormControlLabel
                onChange={() => {
                  let newLayers = [...layersToDownload];
                  if (newLayers.includes(layer.layerName))
                    newLayers = newLayers.filter(
                      name => name !== layer.layerName
                    );
                  else newLayers.push(layer.layerName);
                  this.setState({ layersToDownload: newLayers }, () =>
                    this.updateAmountOfFilesToDownload()
                  );
                }}
                style={{ marginLeft: 20 }}
                control={
                  <Checkbox
                    color="primary"
                    key={layer.layerName}
                    checked={layersToDownload.includes(layer.layerName)}
                  />
                }
                labelPlacement="end"
                label={layer.layerName}
                key={layer.layerName}
              ></FormControlLabel>
            ))}
          </FormControl>
        ))}
      </DialogContent>
    );
  }

  renderDialogActions = () => {
    const {submitInput} = this.props;
    const {exportAsKml} = this.state;

    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column"
        }}
      >
        <div>
          <Typography variant="caption">
            {`Export as .kml`}
          </Typography>
          <Checkbox
            color="primary"
            onChange = {() => {this.setState({exportAsKml: !exportAsKml})}}
            checked={exportAsKml}
          />
        </div>
        <Button
          disabled={this.state.amountOfFilesToDownload < 1}
          style={{ backgroundColor: "#4a5bbb", color: "white", marginRight: 10, marginBottom: 5, marginTop: 5}}
          variant="contained"
          onClick={() => submitInput(this.getDownloadDict(), exportAsKml)}
        >
          {"Download " + this.state.amountOfFilesToDownload + " file(s)"}
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { submitInput, setShowDownloadOnMergeDialog } = this.props;
    return (
      <CustomDialog
        open={true}
        subTitle={"Multiple layers of the same type will be exported as one file"}
        onClose={setShowDownloadOnMergeDialog}
        onSubmit={submitInput}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Download zones"}
      />
    );
  }
}

export default withTheme(DownloadOnMergeDialog);
