import React, { PureComponent } from "react";
import Header from "./Header";
import { GET_CITIES_URL } from "../../constants/remotes";
import axios from "axios";
import RecentlyEdited from "./RecentlyEdited"
import { withFirebase } from "../Firebase";
import CityOverview from "./CityOverview";
import SearchBar from "./SearchBar"
import voiIcon from "../../assets/companyLogos/voi.png";
import logo from "../../assets/companyLogos/logo.png";
import { Grid, Card, Container } from "@material-ui/core";
import { withSnackbar } from "notistack";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Button} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import * as _ from 'lodash';


class HomePage extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      cities: {},
      isAdmin: false,
      currentcities: [],
      expanded: false,
      company: props.match.params.companyName,
      category: 'All cities',
      search: "",
      lastEdit: []
    };
  }

  async getCities() {
    if (this.props.firebase.auth.currentUser) {
      const res = await axios.get(
        GET_CITIES_URL,
        {
          headers: {
            Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
          }
        }
      );
      this.setState({
        cities: res.data.cityAccess,
        loading: res.data.cityAccess[this.state.company] ? false : true,
        isAdmin: res.data.isAdmin
      });

      if (!res.data.cityAccess[this.state.company]) {
        this.props.enqueueSnackbar("You don't have access to any cities!", {
          variant: "error",
          autoHideDuration: 20000
        });
      }

    } else {
      console.log("not logged in");
    }
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getCities();
      }
    });
  }


  // this logic should perhaps be handled elsewhere

  applyCityFilter() {
    const {cities, company} = this.state;
    return cities[company].filter(city => this.fitInCategory(city.locked) && this.fitWithSearch(city.name))
  }

  fitWithSearch(name) {
    return name.includes(this.state.search);
  }

  fitInCategory(locked) {
    const {category} = this.state;
    if (category === "All cities") return true;
    else if (category === "Locked cities") return locked === true;
    else if (category === "Unlocked cities") return locked === false;
  }

  getCompanyLogo = () => {
    const company = this.state.company;
    if (company === "voi") return voiIcon;
    return logo;
  }

  goToAdminPanel = async () => {
    this.props.history.push(
      `/admin/${this.state.company}`
    );
  }

  render() {
    const {cities, company, category, loading, isAdmin} = this.state;
    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center">
          <Header companyLogo={this.getCompanyLogo()} company={company}>
            {isAdmin[company]?
              <Button
                onClick={() => this.goToAdminPanel()}
                variant="contained"
                style={{...styles.buttonStyle, marginRight: 12}}
              >
                <SupervisorAccountIcon
                  style={{ fontSize: 16, color: "#2B2B2B", marginRight: 8 }}
                ></SupervisorAccountIcon>
              Admin Panel
              </Button>
              :null}
          </Header>
          <Grid container>
            {!loading && <RecentlyEdited cities={cities} company={company}/>}
          </Grid>
          <Grid container justify="center" alignItems="center">
            <SearchBar disabled={loading} setCategory={(category) => this.setState({category})} setSearch={(search) => this.setState({search})} category={category} placeholder="Search cities..." hasDropDown={true} style={{paddingLeft: 12}} />
          </Grid>
          <Grid container justify="center" alignItems="center">
            {loading ? (
              <div
                style={{
                  height: "100%",
                  maxWidth: 1233,
                }}
              >
                <Grid container justify="center" alignItems="center">
                  {_.times(12, n => (
                    <Grid key={n} item>
                      <Card style={{
                        width: "280px",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "12px",
                        marginLeft: 12,
                        marginBottom: "24px"
                      }}>
                        <Skeleton animation="wave" variant="rect" height={180} />
                        <Container style={{ paddingLeft: 5, paddingRight: 5, display: 'flex', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center' }}>
                          <Skeleton animation="wave" variant="circle" width={30} height={30} style={{marginRight: 5}} />
                          <Skeleton animation="wave" width={220} height={30} />
                        </Container>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : (
              <CityOverview
                companyLogo={this.getCompanyLogo()}
                cities={this.applyCityFilter()}
                company={company}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

let styles = {
  buttonStyle: {
    borderRadius: 40,
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 12,
    color: "#2B2B2B",
    backgroundColor: "#F4F4F5",
    WebkitBoxShadow: "none",
    MozBoxShadow: "none",
    boxShadow: "none"
  }
}

export default withSnackbar(withFirebase(HomePage));
