import {
  LAYER_TYPE_NPZ,
  LAYER_TYPE_IPZ,
  LAYER_TYPE_SSZ,
  LAYER_TYPE_OPS,
  LAYER_TYPE_CUSTOM,
  LAYER_TYPE_BOUNTY,
  LAYER_TYPE_MPZ,
  LAYER_TYPE_NGZ
} from "./Map";

export const getLayerTypesAsList = () => {
  return [LAYER_TYPE_NPZ, LAYER_TYPE_IPZ, LAYER_TYPE_OPS, LAYER_TYPE_SSZ, LAYER_TYPE_CUSTOM, LAYER_TYPE_BOUNTY, LAYER_TYPE_MPZ, LAYER_TYPE_NGZ]
}

export const getLayerFillColor = layerType => {
  switch (layerType) {
  case LAYER_TYPE_NPZ:
    return [255, 99, 71, 150];
  case LAYER_TYPE_MPZ:
    return [0, 112, 201, 100];
  case LAYER_TYPE_IPZ:
    return [105, 168, 42, 90];
  case LAYER_TYPE_SSZ:
    return [248, 218, 89, 150];
  case LAYER_TYPE_OPS:
    return [61, 188, 217, 90];
  case LAYER_TYPE_BOUNTY:
    return [168, 121, 50, 60];
  case LAYER_TYPE_CUSTOM:
    return [194, 194, 194, 120];
  case LAYER_TYPE_NGZ:
    return [100, 100, 100, 89];
  default:
    return [160, 160, 180, 80];
  }
};

export const getLayerLineColor = layerType => {
  switch (layerType) {
  case LAYER_TYPE_NPZ:
    return [255, 70, 70];
  case LAYER_TYPE_MPZ:
    return [0, 93, 168, 100];
  case LAYER_TYPE_IPZ:
    return [89, 143, 36];
  case LAYER_TYPE_SSZ:
    return [248, 192, 89];
  case LAYER_TYPE_OPS:
    return [160, 160, 180];
  case LAYER_TYPE_BOUNTY:
    return [97, 71, 33, 100];
  case LAYER_TYPE_CUSTOM:
    return [122, 122, 122, ];
  case LAYER_TYPE_NGZ:
    return [44, 44, 44, ];
  default:
    return [0, 0, 0];
  }
};

export const getLayerType = layerName => {
  switch (layerName) {
  case "npz":
  case "No Parking Zones":
    return LAYER_TYPE_NPZ
  case "ipz":
  case "Incentivized Zones":
    return LAYER_TYPE_IPZ
  case "ssz":
  case "Slow Speed Zones":
    return LAYER_TYPE_SSZ
  case "ops":
  case "Operational Zone":
    return LAYER_TYPE_OPS
  case "bounty":
  case "Bounty Zone":
    return LAYER_TYPE_BOUNTY
  case "mpz":
  case "Mandatory Parking Zones":
    return LAYER_TYPE_MPZ
  case "No Go Zones":
  case "ngz":
    return LAYER_TYPE_NGZ
  default:
    return LAYER_TYPE_CUSTOM
  }
}


export const getLayerDescription = layerType => {
  switch (layerType) {
  case LAYER_TYPE_NPZ:
    return "No Parking Zones";
  case LAYER_TYPE_IPZ:
    return "Incentivized Zones";
  case LAYER_TYPE_SSZ:
    return "Slow Speed Zones";
  case LAYER_TYPE_BOUNTY:
    return "Bounty Zone";
  case LAYER_TYPE_OPS:
    return "Operational Zone";
  case LAYER_TYPE_CUSTOM:
    return "Custom Zone"
  case LAYER_TYPE_MPZ:
    return "Mandatory Parking Zones"
  case LAYER_TYPE_NGZ:
    return "No Go Zones"
  default:
    return "Unkown zone type";
  }
};

