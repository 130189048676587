import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";

class AuthenticationOverlay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loggedIn: false, userEmail: null };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({ loggedIn: true, userEmail: authUser.email });
      } else {
        this.setState({ loggedIn: false });
        this.props.enqueueSnackbar("Please sign in!", {
          variant: "warning"
        });
        this.props.history.push(ROUTES.SIGN_IN);
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return ( <div></div> );
  }
}

export default withFirebase(withSnackbar(withRouter(AuthenticationOverlay)));
