import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { isLocalhost } from './utils/env';

export const init = () => {
  if (!isLocalhost) {
    LogRocket.init('0igb1s/geowiz');
    setupLogRocketReact(LogRocket);
  }
}

export const identify = (userId, email) => {
  if (!isLocalhost) {
    LogRocket.identify(userId, { email })
  }
}

export const reportError = (error) => {
  if(!isLocalhost) {
    LogRocket.captureException(error);
  }
}
