import React, { PureComponent } from "react";
import Typography from "@material-ui/core/Typography";
import { withTheme } from "@material-ui/core/styles";
import CityListItemEntry from "./CityListItemEntry";
import { withFirebase } from "../Firebase";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import Citylist from "./CityList";

class RecentlyEdited extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      centerCitites: false,
    }
  }

  componentDidMount() {
    this.updateCenterCities();
    window.addEventListener("resize", this.updateCenterCities);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCenterCities);
  }

  updateCenterCities = () => {
    this.setState({ centerCities: window.innerWidth < 1233 });
  }

  getLastEditedList() {
    const { cities, company } = this.props;
    let lastEdit = [];
    const email = this.props.firebase.auth.currentUser.email;
    Object.entries(cities[company]).forEach(city => {
      if (city[1]["editHistory"][email]) lastEdit.push(city[1]);
    });
    if (lastEdit.length > 4) {
      // Only pick the 4 most recent
      lastEdit.sort((a, b) => {
        a = new Date(a["editHistory"][email]);
        b = new Date(b["editHistory"][email]);
        return a > b ? -1 : a < b ? 1 : 0;
      });
      lastEdit = lastEdit.splice(0, 4);
    }
    return lastEdit;
  }

  buildListItem(company, city, locked, force, editHistory) {
    return (
      <CityListItemEntry
        key={city}
        companyName={company}
        cityName={city}
        locked={locked}
        editHistory={editHistory}
      />
    );
  }

  render() {
    const { company } = this.props;
    const { centerCities } = this.state;
    if (this.getLastEditedList().length === 0) return null;
    return (
      <div
        style={{
          backgroundColor: "#F4F4F5",
          paddingTop: 24,
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div style={{ maxWidth: 1233, width: "100%" }}>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 18,
              marginLeft: 12,
              marginBottom: 24,
              alignSelf: "flex-start"
            }}
          >
            Recently edited
          </Typography>
          <Citylist cities={this.getLastEditedList()} company={company} center={centerCities} />
        </div>
      </div>
    );
  }
}

export default withRouter(
  withSnackbar(withTheme(withFirebase(RecentlyEdited)))
);
