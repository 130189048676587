import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ADD_USER_URL } from '../../constants/remotes';
import axios from "axios";
import { withTheme } from "@material-ui/core/styles";
import CustomDialog from "../Editor/CustomDialog"
import { withSnackbar } from "notistack";
import { withFirebase } from "../Firebase";

class AddUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMail: "",
      userRole: "",
      chosenCities: [],
      companyName: this.props.companyName,
      showError: false,
    };
  }

  addNewUser = async () => {
    const {userMail, userRole, chosenCities, companyName} = this.state;
    if(userMail === "" || userRole === ""){
      this.setState({showError : true})
    }else{
      try{
        const res = await axios.post(
          ADD_USER_URL,
          {userMail, userRole, chosenCities, companyName},
          {
            headers: {
              Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
            }
          }
        );
        if(res.status === 200){
          this.props.enqueueSnackbar("Successfully added " + userMail, { 
            variant: "success",
            autoHideDuration: 4000
          });
        }else if(res.status === 401){
          this.props.enqueueSnackbar("Not authorized to add users", { 
            variant: "error",
            autoHideDuration: 4000
          });
        }
      }catch(error){
        this.props.enqueueSnackbar("Something went wrong, try again", {
          variant: "error",
          autoHideDuration: 4000
        });
        console.log(error)
      }
      this.setState({
        userMail: "",
        userRole: "",
        chosenCities: []
      })
      this.props.refreshUsers();
    }
  }

  handleMailChange = event => {
    this.setState({userMail: event.target.value})
  }

  handleRoleChange = event => {
    this.setState({userRole: event.target.value})
  }

  hideError = () => {
    this.setState({showError: false})
  }

  changeCityAccesses = (event, values) => {
    let chosenCities = [];
    values.forEach(city => {
      chosenCities.push(city);
    });
    this.setState({chosenCities});
  }

  renderDialogContent = () => {
    const { cityList } = this.props;
    let cityNames = [];
    cityList.forEach(city => {
      cityNames.push(city.name);
    })
    const { userMail, userRole, showError } = this.state;
    return (
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          style={{ marginBottom: 20, minWidth: 300 }}
          onChange={this.handleMailChange}
          value={userMail}
          autoFocus
          label="Mail*"
          onFocus={this.hideError}
          autoComplete={"off"}
          margin="dense"
          id="mail">
        </TextField>
        <FormControl
          style={{ marginBottom: 20, minWidth: 300 }}
        >
          <InputLabel id="role">Role*</InputLabel>
          <Select
            value={userRole}
            onFocus={this.hideError}
            onChange={this.handleRoleChange}>
            <MenuItem value="User">
              User
            </MenuItem>
            <MenuItem value="Admin">
              Admin
            </MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          multiple
          style={{marginBottom: 16}}
          id="tags-standard"
          options={cityNames}
          onChange={this.changeCityAccesses}
          value={this.state.chosenCities}
          getOptionLabel={(name) => name.replace(/^./, name[0].toUpperCase())}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cities"
              placeholder="Cities" />
          )}>
        </Autocomplete>
        {showError?
          <span style={{color: "red"}}>Please fill out all fields with *</span>
          :null}
      </DialogContent>
    );
  }

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          style={{ backgroundColor: "#4a5bbb", color: "white" }}
          variant="contained"
          onClick={() => this.addNewUser()}
        >
        Add user
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { showAddUser, setShowAddUser } = this.props;
    return (
      <CustomDialog
        open={showAddUser}
        onClose={setShowAddUser}
        subTitle={"Add a user with the given email and role"}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Add new user"}
      />
    );
  }
}

export default withFirebase(withTheme(withSnackbar(AddUserDialog)));
