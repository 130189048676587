import React from "react";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { area } from "@turf/turf";
import { getLineStringLength } from "../../utils/geo/operations";


const MeasureToolTip = props => {
  const { editData, visible, measureType } = props;

  function renderMeasure(measureType) {
    switch (measureType) {
    case "area":
      return "km² : " + getArea(editData);
    case "distance":
      return "m : " + getLength(editData);
    default:
      return null
    }
  }

  if (!visible || !editData) return null;
  getArea(editData);
  return (
    <React.Fragment>
      <Paper
        style={{
          position: "absolute",
          right: 0,
          top: 110,
          zIndex: 999,
          width: 160,
          margin: 16,
          padding: 8,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          style={{
            textAlign: "center",
            color: "#A2BAD2",
            fontWeight: 600
          }}
        >
          {renderMeasure(measureType)}
        </Typography>
      </Paper>
    </React.Fragment>
  );
};

function getArea(editData) {
  try {
    const polygon = editData.features[0];
    const a = area(polygon) * 0.000001;
    return ("" + a).slice(0,5);
  } catch (error) {
    console.log(error);
  }
}

function getLength(editData) {
  const length = getLineStringLength(editData.features[0]);
  return Math.round(length * 100) / 100;
}

export default MeasureToolTip;
