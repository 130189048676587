import React from "react";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputAdornment } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

const SearchBar = props => {
  const { setSearch, setCategory, category, hasDropDown, style, placeholder, disabled } = props;
  return (
    <React.Fragment>
      <div style={{maxWidth: 1192, width:"100%", height: 50, marginTop: 20, marginBottom: 30, paddingRight: 12, display: "flex", alignItems: "center", ...style}}>
      
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <TextField
            onChange={e => setSearch(e.currentTarget.value)}
            style={{
              width: "100%",
              height: "40px"
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            label={placeholder}
            disabled={disabled}
          ></TextField>
          {hasDropDown?
            <Select
              onChange={e => setCategory(e.target.value)}
              value={category}
              style={{
                height: 40,
                width: 150,
                marginTop: 15,
                fontSize: 14,
                fontWeight: 500,
                color: "#4B4B4B"
              }}
              disabled={disabled}
            >
              <MenuItem value={"Unlocked cities"}>Unlocked cities</MenuItem>
              <MenuItem value={"Locked cities"}>Locked cities</MenuItem>
              <MenuItem value="All cities"> All cities </MenuItem>
            </Select>
            :null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchBar;
