import React, { Component } from 'react';
import {
  DialogActions, Button,
  TextField,
  FormControl, InputLabel,
} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getLayerTypesAsList, getLayerDescription } from "./LayerStyles";
import CustomDialog from "./CustomDialog";
import { withTheme } from "@material-ui/core/styles";

class CreateLayerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layerName: "",
      layerType: "No Parking Zones"
    }
  }

  handleLayerTypeChange = event => {
    this.setState({ layerType: event.target.value })
  }
  handleNameChange = event => {
    this.setState({ layerName: event.target.value })
  }

  renderDialogContent = () => {
    const layerTypes = getLayerTypesAsList().map(layerType => getLayerDescription(layerType));
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 32,
          paddingLeft: 32,
          paddingRight: 32,

        }}
      >
        <TextField
          style={{ marginBottom: 40, minWidth: 300 }}
          autoFocus
          label="Name"
          autoComplete={"off"}
          margin="dense"
          id="name"
          onChange={this.handleNameChange}>
        </TextField>
        <FormControl
          style={{ marginBottom: 16, minWidth: 300 }}
        >
          <InputLabel id="Zone">Zone</InputLabel>
          <Select
            labelId="Zone"
            onChange={this.handleLayerTypeChange}
            value={this.state.layerType}
          >
            {layerTypes.map(layerType => (
              <MenuItem key={layerType} value={layerType}>
                {layerType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
        }}
      >
        <Button variant="contained" style={{ backgroundColor: "#4a5bbb", color: "white" }} onClick={() => this.props.submitInput({
          layerType: this.state.layerType,
          layerName: this.state.layerName.trim()
        }
        )}>
        Add new layer
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { title, submitInput, onDialogIsOpen, isOpen } = this.props;
    return (
      <CustomDialog
        open = {isOpen}
        onClose = {onDialogIsOpen}
        onSubmit = {submitInput}
        renderDialogContent = {this.renderDialogContent}
        renderDialogActions = {this.renderDialogActions}
        title = {title}
      />
    )
  }
}

export default withTheme(CreateLayerDialog);
