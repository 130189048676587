import React, { Component } from 'react';
import {
  Button,
  Box,
  Typography,
  Link,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { readFile } from "../../utils/FileHandler";
import { validateGeoJson, optimizeFeatures, containsPoints, convertPoints } from "../../utils/geo/operations";
import { withSnackbar } from "notistack";
import LinearProgress from '@material-ui/core/LinearProgress';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CustomDialog from "./CustomDialog";
import toGeoJSON from "@mapbox/togeojson"


const AcceptedFileTypes = ["geojson", "json", "kml"]

class UploadDialog extends Component {


  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      isLoadingFile: false,
      currentProgress: 0,
      uploadCompleted: false,
      dragCounter: 0,
      file: null
    }
  }


  handleLayerTypeChange = event => {
    this.setState({ layerType: event.target.value })
  }
  handleNameChange = event => {
    this.setState({ layerName: event.target.value })
  }

  handleProgressTimer = () => {
    this.setState({ isLoadingFile: true, currentProgress: 0 });

    let interval = setInterval(() => {
      this.setState({ currentProgress: this.state.currentProgress + 5 }); // TODO: avoid it goes 100+
      if (this.state.currentProgress >= 100) {
        clearInterval(interval);
        this.setState({ uploadCompleted: true, isLoadingFile: false })
      }
    }, 100)
  }

  handleBrowse = (e) => {
    this.handleUpload(e.target.files)
  }

  getFileType(name) {
    return name.split(".").pop()
  }

  convertToKml(data) {
    var kml = new DOMParser().parseFromString(data, "text/xml");
    return toGeoJSON.kml(kml)
  }

  handleUpload = async (files) => {
    this.setState({dragging: false})
    let fileType = this.getFileType(files[0].name)
    console.log(fileType);
    if (!AcceptedFileTypes.includes(fileType)) {
      console.log(files[0].name);
      this.props.enqueueSnackbar("The selected file is not an accepted file type", { variant: "error", autoHideDuration: 4000 })
      return;
    }
    this.handleProgressTimer();
    let result = await readFile(files[0]); // Todo read a list of files
    // console.log(result);
    try {
      let geoJson;
      if (fileType === "kml") {
        geoJson = this.convertToKml(result)
      } else {
        geoJson = JSON.parse(result);
      }
      if (containsPoints(geoJson)) {
        geoJson = convertPoints(geoJson);
        this.props.enqueueSnackbar("The file contains points, and has been transformed. ", { variant: "warning", autoHideDuration: 2000 })
      }
      if (!validateGeoJson(geoJson)) throw Error;
      let optimized = optimizeFeatures(geoJson);
      if (!validateGeoJson(optimized)) {
        this.props.enqueueSnackbar("The file was not able to be optimized, using original file.", { variant: "warning", autoHideDuration: 2000 })
      } else {
        geoJson = optimized;
      }
      this.setState({file: geoJson})
    } catch (e) {
      this.props.enqueueSnackbar("The selected file is unvalid. Please submit a valid geojson file.", { variant: "error", autoHideDuration: 4000 })
    }
  }

  handleClose = () => {
    this.props.setShowUploadDialog();
    this.setState({
      isLoadingFile: false,
      uploadCompleted: false
    })
  }

  renderDialogActions = () => {
    return (
      <div>

        {this.state.uploadCompleted ?
          <Box style={{marginBottom: 32, marginRight: 48,  paddingLeft: 120, display: "flex", justifyContent: "space-between"}}>
            <Button 
              variant= "contained" 
              style={{backgroundColor: "#F4F4F5"}}
              onClick={() => {
                this.props.setShowMergeLayers(true);
                this.props.setShowUploadDialog(false);  
                this.props.setDataToUpload(this.state.file);
                this.setState({file: null, uploadCompleted: false})
              }}
            >
            Merge with existing layer
            </Button>
            <Button variant ="contained" style={{marginLeft: 10, backgroundColor: "#4a5bbb", color: "white"}}
              onClick={() => {
                this.props.setCreateDialogIsOpen(true);
                this.props.setShowUploadDialog(false);
                this.props.setDataToUpload(this.state.file);
                this.setState({file: null, uploadCompleted: false})
              }}
            >
            Add as new layer
            </Button>
          </Box>
          :
          null}
      </div>

      
    )
  }

  renderDialogContent = () => {
    return (
      <div style={{
        display:"flex", 
        flexDirection: "column", 
        justifyContent: "center",
        marginBottom: 16,
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
      }}>
        <input id="myInput"
          type="file"
          ref={(ref) => this.upload = ref}
          style={{ display: 'none' }}
          onChange={this.handleBrowse.bind(this)}
        />
        <div style={{
          borderStyle: "dashed",
          borderWidth: 2,
          borderColor: this.state.dragging ? "#0099FF" : "#B8CADB",
          marginBottom: 12,
          height: 230,
          width: "100%",
          borderRadius: 10,
          backgroundColor: this.state.dragging ? "#e6f5ff" : "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
        onDragEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()
          this.setState({dragCounter: this.state.dragCounter + 1})
          if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true })
          }
        }}
        onDragLeave={(e) => {
          this.setState({dragCounter: this.state.dragCounter - 1})
          e.preventDefault();
          e.stopPropagation();
          if (this.state.dragCounter > 0) return;
          this.setState({ dragging: false })
        }}

        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.handleUpload(e.dataTransfer.files)
            e.dataTransfer.clearData()

          }
        }}
        >
          <BackupOutlinedIcon style={{color: "#4a5bbb", fontSize: 90 }}></BackupOutlinedIcon>
          <span style={{display: "flex", marginTop: 10}}>
            <Typography style ={{marginRight: 3}}>Drop your files here, or </Typography>
            <Link underline="always" component="button" variant="body1" style ={{color: "#4a5bbb"}}
              onClick={() => { this.upload.click() }}
            >browse
            </Link>
          </span>
          <Typography style={{marginTop: 10, marginBottom: 20, color: "#A2BAD2"}} variant="caption">Supports: GEOJSON, KML</Typography>
        </div>
        {this.state.isLoadingFile && !this.state.uploadCompleted ?
          <div style={{ 
            paddingBottom: 20, 
            marginTop: 20, 
            height: 80, 
            borderRadius: 10, 
            backgroundColor: "#f7fafe",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around"
          }}>
            <Typography style={{marginTop: 20, marginLeft: 10, fontWeight: 500}} >Uploading...</Typography>
            <LinearProgress style={{backgroundColor: "#f7fafe"}} variant="determinate" value={this.state.currentProgress} />
          </div>
          : (this.state.uploadCompleted && !this.state.isLoadingFile) ?
            <div style={{ 
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 20, 
              height: 80, 
              borderRadius: 10, 
              backgroundColor: "#f7fafe",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
              <Typography style={{fontWeight: 500}}>Upload Complete</Typography>
              <div style={{
                width: 35, 
                height: 35, 
                borderRadius: "50%", 
                display: "flex",
                justifyContent: "center",
                alignItems:"center",
                backgroundColor: "#CEF5E4"}}>
                <CheckRoundedIcon style={{color: "#58CB61"}}></CheckRoundedIcon>
              </div>
            </div>
            :
            null
        }
      </div>
    )
  }


  render() {
    return (
      <CustomDialog
        open = {this.props.showUploadDialog}
        onClose = {this.handleClose}
        renderDialogContent = {this.renderDialogContent}
        renderDialogActions = {this.renderDialogActions}
        title = "Upload Zones from your computer"
        subTitle = "Create new layer or add to existing layer"
      />
    )
  }
}

export default withSnackbar(withTheme(UploadDialog));