import React, { Component } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Typography,
  FormLabel,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import CustomDialog from "./CustomDialog"
import LayerTag from "./LayerTag";
import { getLayerDescription } from "./LayerStyles";
import { area } from "@turf/turf";
import { createFc } from "../../utils/geo/operations";

class CalculateDifferenceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenLayers: [],
      result: createFc([])
    };
  }

  getLayerTypes = () => {
    return ["LAYER_TYPE_OPS", "LAYER_TYPE_NPZ"];
  };

  getLayersOfLayerType = layerType => {
    const { layers } = this.props;
    return layers.filter(layer => layer.layerType === layerType);
  };

  getArea = editData => {
    try {
      const polygon = createFc([editData]).features[0];
      const a = area(polygon) * 0.000001;
      return ("" + a).slice(0,5);
    } catch (error) {
      console.log(error);
    }
  }

  renderDialogContent = () => {
    const { onSubmit } = this.props;
    const { chosenLayers } = this.state;
    return (
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {this.getLayerTypes().map(layerType => (
          <FormControl component="fieldset" key={layerType}>
            <div
              style={{
                display: "flex",
                marginTop: 10
              }}
            >
              <LayerTag editing layerType={layerType}></LayerTag>
              <FormLabel
                component="legend"
                style={{ marginLeft: 10, marginTop: 5 }}
              >
                {getLayerDescription(layerType)}
              </FormLabel>
            </div>
            {this.getLayersOfLayerType(layerType).filter(x => !x.hidden).map(layer => (
              <FormControlLabel
                onChange={() => {
                  let newLayers = [...chosenLayers];
                  if (newLayers.includes(layer.layerName))
                    newLayers = newLayers.filter(
                      name => name !== layer.layerName
                    );
                  else newLayers.push(layer.layerName);
                  this.setState({
                    chosenLayers: newLayers,
                    result: onSubmit(newLayers)
                  });
                }}
                style={{ marginLeft: 20 }}
                control={
                  <Checkbox
                    color="primary"
                    key={layer.layerName}
                    checked={chosenLayers.includes(layer.layerName)}
                  />
                }
                labelPlacement="end"
                label={layer.layerName}
                key={layer.layerName}
              ></FormControlLabel>
            ))}
          </FormControl>
        ))}
      </DialogContent>
    );
  }

  renderDialogActions = () => {
    const { result, chosenLayers } = this.state;
    let showResult = chosenLayers.some(layer => this.props.getLayerType(layer) === "LAYER_TYPE_OPS") && chosenLayers.some(layer => this.props.getLayerType(layer) === "LAYER_TYPE_NPZ");
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <Typography
          variant="caption"
          style={{
            textAlign: "center",
            color: "#7e92a6",
            fontWeight: 800,
            fontSize: 16
          }}
        >
          {showResult? 
            "km² : " + this.getArea(result)
            :"Choose zones..."}
        </Typography>
      </DialogActions>
    )
  }

  render() {
    const { setCalculateDifferenceDialogOpen, calculateDifferenceDialogOpen } = this.props;
    return (
      <CustomDialog
        open={calculateDifferenceDialogOpen}
        subTitle={"Choose zones to calculate available parking area"}
        onClose={ () => {
          this.setState({chosenLayers: [], result: createFc([])})
          setCalculateDifferenceDialogOpen(false)
        }}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Calculate difference"}
      />
    );
  }
}

export default withTheme(CalculateDifferenceDialog);
