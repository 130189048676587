import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withTheme } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import AppSplash from "../../assets/images/appsplash.png";

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  password: ""
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
    var urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get("email");
    if(email !== null){
      this.setState({email})
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch(error => {
        this.props.enqueueSnackbar(error.message, { variant: "error" });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if (
      (this.state.email !== "" &&
        event.target.name === "password" &&
        event.target.value !== "") ||
      (this.state.password !== "" &&
        event.target.name === "email" &&
        event.target.value !== "")
    ) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  render() {
    return (
      <Grid container style={{ height: "100vh", width: "100%" }}>
        <Grid item xs style={{ backgroundColor: "#FFF" }}>
          <Container
            component="main"
            maxWidth="xs"
            style={{ paddingLeft: 0, paddingRight: "3px" }}
          >
            <div
              style={{
                marginTop: this.props.theme.spacing(20),
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold", color: "#474747" }}
              >
                Sign up
              </Typography>
              <form
                onSubmit={this.onSubmit}
                noValidate
                style={{ marginTop: this.props.theme.spacing(3) }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      value={this.state.firstName}
                      onChange={this.onChange}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      value={this.state.lastName}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.onChange}
                />

                <Grid container style={{ marginTop: "30px" }}>
                  <Grid
                    item
                    xs
                    style={{ maxWidth: "150px", marginRight: "25px" }}
                  >
                    <Button
                      disabled={!this.state.valid}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        margin: this.props.theme.spacing(2, 0, 2),
                        padding: "8px"
                      }}
                    >
                      Sign up
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="body2" style={{ color: "#77797E" }}>
                  {"Already have an account? "}
                  <Link href={ROUTES.SIGN_IN} variant="body2">
                    {"Sign in"}
                  </Link>
                </Typography>
              </form>
            </div>
          </Container>
        </Grid>
        {window.innerWidth > 800 ? (
          <Grid
            item
            xs={6}
            style={{ backgroundColor: "#EFEFFA", overflow: "hidden" }}
          >
            <img
              alt=""
              src={AppSplash}
              style={{ height: "600px", transform: "translate(150px, 75px)" }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

const SignUpForm = compose(
  withSnackbar,
  withTheme,
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
