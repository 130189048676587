import React, { PureComponent } from "react";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import {Typography, Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/formatting";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import axios from "axios";
// import { REQUEST_ACCESS_URL } from "../../constants/remotes";
import { withFirebase } from "../Firebase";
import { withSnackbar } from "notistack";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import placeholder from "../../assets/images/placeholder.png";

class CityListItemEntry extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // hasRequestedAccess: false,
      imageUrl: `https://storage.googleapis.com/geowiz-prod.appspot.com/cities/${this.props.companyName}/${this.props.cityName}/screenshot.png`
    };
  }

  edit = async () => {
    this.props.history.push(
      `/home/editor/${this.props.companyName}/${this.props.cityName}`
    );
  };

  // requestAccess = async () => {
  //   this.setState({ hasRequestedAccess: true });
  //   const { cityName, companyName } = this.props;
  //   try {
  //     const res = await axios.post(
  //       REQUEST_ACCESS_URL,
  //       { cityName, companyName },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
  //         }
  //       }
  //     );
  //     if (res.status === 200) {
  //       this.props.enqueueSnackbar("Received Request. Try to refresh", {
  //         variant: "info",
  //         autoHideDuration: 4000
  //       });
  //     }
  //   } catch (error) {
  //     this.props.enqueueSnackbar("Something went wrong, try again", {
  //       variant: "error",
  //       autoHideDuration: 4000
  //     });
  //     this.setState({ hasRequestedAccess: false });
  //   }
  // };

  secondsToDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  getLastEditedAuthor() {
    let maxDate = null;
    let author = null;
    Object.entries(this.props.editHistory).forEach(entry => {
      let timestamp = this.secondsToDateTime(entry[1]._seconds);
      if (timestamp > maxDate) {
        maxDate = timestamp;
        author = entry[0];
      }
    });
    return author;
  }

  render() {
    const {
      cityName,
      locked,
      editHistory
    } = this.props;
    return (
      <Card
        style={{
          width: "280px",
          display: "flex",
          flexDirection: "column",
          marginRight: "12px",
          marginLeft: 12,
          marginBottom: "24px"
        }}
      >
        <CardActionArea onClick={() => {locked ?
          this.props.enqueueSnackbar("You do not have access to this city. Reach out to your admin.", {
            variant: "error",
            autoHideDuration: 4000
          }) : this.edit()}}>
          <div>
            <CardMedia
              component="img"
              height="180"
              onError = {() => this.setState({imageUrl: placeholder})}
              image={this.state.imageUrl}
              style={{ WebkitFilter: locked ? "opacity(40%)" : null }}
            />
            {locked ? (
              <Tooltip title={"You do not have access to this city"}>
                <Box
                  // onClick={() => this.requestAccess()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    minWidth: "80px",
                    minHeight: "80px",
                    msTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    borderRadius: "50%"
                  }}
                  // disabled={this.state.hasRequestedAccess}
                >
                  <LockOutlinedIcon style={{color: "#495bbe"}}/>
                </Box>
              </Tooltip>
            ) : null}
          </div>

          <CardContent>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <LocationCityIcon
                style={{ fontSize: 20, color: "#495bbe" }}
              ></LocationCityIcon>
              <Typography
                color="inherit"
                variant="body1"
                underline="none"
                style={{ paddingLeft: 10, textAlign: "bottom" }}
                disabled={locked}
              >
                {capitalizeFirstLetter(cityName)}
              </Typography>
            </div>
            {editHistory ? (
              <Typography
                variant="caption"
                style={{ fontSize: "0.7rem", color: "#757575" }}
              >
                {`Last edited: ${this.secondsToDateTime(
                  editHistory[this.getLastEditedAuthor()]._seconds
                ).toDateString()} by \n ${this.getLastEditedAuthor()}`}
              </Typography>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withSnackbar(withFirebase(withRouter(CityListItemEntry)));
