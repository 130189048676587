import React, { Component } from "react";
import PropTypes from "prop-types";
import DeckGL from "@deck.gl/react";
import { FlyToInterpolator} from "@deck.gl/core";
import { GeoJsonLayer } from "@deck.gl/layers";
import { EditableGeoJsonLayer, ViewMode, DrawPolygonMode, ModifyMode, DrawRectangleMode, DrawCircleFromCenterMode, MeasureDistanceMode, DrawLineStringMode  } from "nebula.gl";
import ReactMapGL from "react-map-gl";
import { getCityZones, saveCityZones } from "../../utils/map";
import { withFirebase } from "../Firebase";
import { union, subtract, createFc, getValidatedMultiPolygon, convertPolygonToFeatureCollection, convertMultiPolygonToFeatureCollection, unionToFc, isValidFeatureCollection, combineLayers, getFeatureToFill, getPolygonsWithinCoords, buffPolygons, optimizeFeatures, removeSmallHoles, prepareKMLdata } from "../../utils/geo/operations";
import LayerControls from "./LayerControls";
import InfoDialog from "./InfoDialog";
import { getCoords, unkinkPolygon, center, area, point } from "@turf/turf";
import { withTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapOptions from "./MapOptions";
import { saveAs } from "file-saver";
import StyleToggler from "./StyleToggler";
import SaveAlert from "./SaveAlert";
import UploadDialog from "./UploadDialog";
import LayerControlsList from "./LayerControlsList";
import { getLayerFillColor, getLayerLineColor, getLayerType } from "./LayerStyles";
import CreateLayerDialog from "./CreateLayerDialog";
import DownloadLayerDialog from "./DownloadLayerDialog";
import OSMPicker from "./OSMpicker";
import MergeLayerDialog from "./MergeLayerDialog";
import ToggleLayersButton from "./ToggleLayersButton";
import DownloadOnMergeDialog from "./DownloadOnMergeDialog";
import RenameDialog from "./RenameDialog";
import { withSnackbar } from "notistack";
import 'mapbox-gl/dist/mapbox-gl.css';
import fetchOSMData from "./OSMFetcher"
import Layerstack from "./Datastructures/Layerstack";
import * as analytics from "../../analytics";
import EditSVG from '../../assets/icons/edit.svg';
import bucketSVG from '../../assets/icons/bucket.svg';
import { withRouter } from "react-router-dom";
import MeasureToolTip from "./MeasureToolTip";
import FeatureDescription from "./FeatureDescription";
import Joyride from 'react-joyride'
import JoyRideSteps from './JoyRideSteps'
import TransformationsDialog from "./TransformationsDialog"
import CalculateDifferenceDialog from './CalculateDifferenceDialog'
import tokml from "tokml"
import RulerTool from './RulerTool';
import MDSViewer from "./MDSViewer";
import SatelliteIcon from "../../assets/icons/satellite 1.png";
import StreetIcon from "../../assets/icons/street.png";
import MonochromeIcon from "../../assets/icons/monochrome.png";


// Offline city
import { malmoNPZ } from "../../assets/demoCities/Malmo/npz.js";
import { malmoOPS } from "../../assets/demoCities/Malmo/ops.js";
import { malmoSSZ } from "../../assets/demoCities/Malmo/ssz.js";
import Toolbar from "./Toolbar";
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Geocoder from "react-map-gl-geocoder";

const mapStyles = {
  satelliteStyle: {
    url: "mapbox://styles/mapbox/satellite-streets-v9",
    image: SatelliteIcon
  },
  standardStyle: {
    url: "mapbox://styles/mapbox/streets-v11",
    image: StreetIcon
  },
  monochromeStyle: {
    url: "mapbox://styles/kongkille/ckj5nubd8dord19qms2vvvx5b",
    image: MonochromeIcon
  },
}

const layerStackLimit = 10;

export const LAYER_TYPE_NPZ = "LAYER_TYPE_NPZ";
export const LAYER_TYPE_IPZ = "LAYER_TYPE_IPZ";
export const LAYER_TYPE_SSZ = "LAYER_TYPE_SSZ";
export const LAYER_TYPE_OPS = "LAYER_TYPE_OPS";
export const LAYER_TYPE_BOUNTY = "LAYER_TYPE_BOUNTY";
export const LAYER_TYPE_MPZ = "LAYER_TYPE_MPZ";
export const LAYER_TYPE_CUSTOM = "LAYER_TYPE_CUSTOM";
export const LAYER_TYPE_NGZ = "LAYER_TYPE_NGZ";

const ACCEPTABLE_TYPES = new Set(["movePosition", "addPosition", "addFeature", "finishMovePosition", "removePosition", "addTentativePosition"]);

class Map extends Component {
  constructor(props) {
    super(props);
    const MDS_CITIES = ["bergen", "seville"]
    this.state = {
      joyrideSteps: JoyRideSteps,
      mapStyle: mapStyles["standardStyle"].url,
      drawMode: "view",
      editMode: "view",
      subTypeShape: "Polygon",
      addTypeShape: "Polygon",
      bucketOpen: false,
      bucketMenuPos: { x: 0, y: 0 },
      selectedFeatureIndexes: [],
      haveBeenEdited: false,
      editData: createFc([]),
      layers: [],
      layerDidInit: false,
      lastLayers: [],
      isLoading: false,
      createLayerDialogIsOpen: false,
      isSaving: false,
      currentUploadingFile: null,
      showSnackbar: false,
      showOSMpicker: false,
      showRenameDialog: false,
      amountOfLayersSelected: 0,
      amountOfLayers: 0,
      layersToDelete: [],
      showOnboarding: false,
      layerToBeEdited: null,
      showMergeLayers: false,
      showDownloadLayerId: null,
      visibleData: null,
      additiveLayerName: null,
      layerBeingModified: null,
      polygonBeingModified: null,
      showDownloadOnMergeDialog: false,
      showLayersPanel: true,
      showUploadDialog: false,
      dataToUpload: null,
      OSMBoundingBox: {},
      displayMeasureToolTip: false,
      showTransformations: false,
      originalModifyPolygon: null,
      displayHiddenLayers: false,
      calculateDifferenceDialogOpen: false,
      measureType: "",
      displayMDSOverview: false,
      shouldDisplayMDS: MDS_CITIES.includes(props.cityName),
      showInfoDialog: false,
      viewState: {
        latitude: 55.7577,
        longitude: 12.4376,
        zoom: 3,
        pitch: 0,
        bearing: 0
      }
    };

    this.bindMethods();
    this.idCounter = 0;
    this.layerStack = new Layerstack(layerStackLimit, this.setLayerData);
  }

  bindMethods() {
    this._onViewStateChange = this._onViewStateChange.bind(this);
    this._onVisibilityChange = this._onVisibilityChange.bind(this);
    this.setCreateDialogIsOpen = this.setCreateDialogIsOpen.bind(this);
    this.createCustomLayer = this.createCustomLayer.bind(this);
    this.uploadCustomGeoJson = this.uploadCustomGeoJson.bind(this);
  }

  _onVisibilityChange = id => {
    const { layers } = this.state;
    const layer = this.getLayer(id);
    if (layer.hidden) return;
    layer.visible = !layer.visible;
    if (!layer.visible) {
      layer.editing = false;
      this.updateAmountOfLayersSelected();
    }
    this.setState({ layers }, this.updateLocalStorageWithLayerinfo);
    if (this.state.editMode === 'paintBucket') this.updatePaintBucket();
  }

  updateLocalStorageWithLayerinfo = () => {
    const { layers } = this.state;
    const { companyName, cityName } = this.props;
    localStorage.setItem(`${companyName}_${cityName}_hidden_layers`, JSON.stringify(layers.filter(l => l.hidden).map(l => l.layerName)));
    localStorage.setItem(`${companyName}_${cityName}_visible_layers`, JSON.stringify(layers.filter(l => l.visible).map(l => l.layerName)));
    localStorage.setItem(`${companyName}_${cityName}_editing_layers`, JSON.stringify(layers.filter(l => l.editing).map(l => l.layerName)));
  }

  getLayer(id) {
    console.log(id);
    const { layers } = this.state;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].id === id) return layers[i];
    }
    throw Error("No layer matched the given id.");
  }

  getLayerFromName = (name) => {
    const { layers } = this.state;
    return layers.find(layer => layer.layerName === name);
  }

  getLayersOfType(layerType) {
    return this.state.layers.filter((layer) => layer.layerType === layerType);
  }

  setShowOSMPicker = (showOSMpicker) => {
    this.setState({ showOSMpicker });
  }

  setDataToUpload = (dataToUpload) => {
    this.setState({ dataToUpload });
  }

  setShowMergeLayers = (showMergeLayers) => {
    this.setState({ showMergeLayers });
  }

  setShowDownloadLayerId = (showDownloadLayerId) => {
    this.setState({ showDownloadLayerId })
  }

  setShowTransformations = (showTransformations) => {
    this.setState({ showTransformations });
  }

  setShowDownloadOnMergeDialog = (showDownloadOnMergeDialog) => {
    if (this.state.polygonBeingModified) this.commitModify();
    this.setState({ showDownloadOnMergeDialog });
  }

  setIsSaving = (isSaving) => {
    this.setState({ isSaving });
  }
  setShowRenameDialog = (showRenameDialog) => {
    this.setState({ showRenameDialog });
  }

  setShowInfo = (showInfoDialog) => {
    this.setState({ showInfoDialog });
  }

  setDrawMode = (drawMode) => {
    this.setState({drawMode});
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
      this.getZones();
    });
    document.addEventListener("keydown", this._handleKeyDown);
    document.addEventListener("mousemove", this._handleMouseMove);
  }

  componentDidUpdate() {
    if (this.hasUnsavedChanges()) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  _handleKeyDown = (event) => {
    const { subTypeShape, addTypeShape, polygonBeingModified } = this.state;
    switch (event.keyCode) {
    case 27: // escape
      this.stopDrawing();
      break;
    case 90: // z
      if (event.metaKey && event.shiftKey) this.layerStack.redo(); // Mac
      else if (event.ctrlKey || event.metaKey) {
        if (this.state.editMode === "measure" && getCoords(this.state.editData.features[0]).length > 1) { // Undoing when measuring, doesnt update visuals on map
          let updatedData = this.state.editData;
          updatedData.features[0].geometry.coordinates.splice(-1, 1);
          this._onEdit(updatedData);
        } else {
          if (polygonBeingModified) {
            this.commitModify(true);
            return;
          }
          this.layerStack.undo();
        }
      }
      break;
    case 89: // y
      if (event.ctrlKey) this.layerStack.redo(); // Win
      break;
    case 13: // enter
      if (polygonBeingModified) this.commitModify();
      break;
    case 83: // (s)ave
      if (event.ctrlKey) {
        event.preventDefault();
        this.saveZones();
      }
      break;
    case 76: // new (l)ayer
      if (event.ctrlKey) {
        event.preventDefault();
        this.setCreateDialogIsOpen(true);
      }
      break;
    case 68: // (d)isplay hidden layers
      if (event.ctrlKey) {
        event.preventDefault();
        this.setDisplayHiddenLayers();
      }
      break;
    case 80: // (p)an
      if (event.ctrlKey) {
        this.changeToolWithKeybind(event, "view");
      }
      break;
    case 65: // (a)dd
      if (event.ctrlKey) {
        this.changeToolWithKeybind(event, "add" + addTypeShape);
      }
      break;
    case 82: // (r)emove
      if (event.ctrlKey) {
        this.changeToolWithKeybind(event, "sub" + subTypeShape);
      }
      break;
    case 70: // (f)ill
      if (event.ctrlKey) {
        this.setState({ bucketOpen: true, bucketMenuPos: this.mousePos });
        this.changeToolWithKeybind(event, "paintBucket");
      }
      break;
    case 77: // (m)odify
      if (event.ctrlKey) {
        this.changeToolWithKeybind(event, "modify");
      }
      break;
    case 9: // tab
      event.preventDefault();
      this.setShowLayersPanel(!this.state.showLayersPanel);
      break;
    default:
      return null;
    }
  }

  isMapFocus = () => {
    const { showDownloadOnMergeDialog, showMergeLayers, showTransformations, showUploadDialog, showRenameDialog, showOSMpicker, calculateDifferenceDialogOpen, createLayerDialogIsOpen } = this.state;
    return !showDownloadOnMergeDialog && !showMergeLayers && !showTransformations && !showUploadDialog && !showRenameDialog && !showOSMpicker && !calculateDifferenceDialogOpen && !createLayerDialogIsOpen;
  }

  changeToolWithKeybind = (event, mode) => {
    if (this.isMapFocus()) {
      event.preventDefault();
      if (this.state.polygonBeingModified) this.commitModify();
      this.changeEditMode(event, mode);
    }
  }

  _handleMouseMove = (event) => {
    this.mousePos = { x: event.screenX, y: event.screenY };
  }

  startModifying(coord) {
    if (this.state.polygonBeingModified) {
      return;
    }
    setTimeout(() => { // father forgive me 🙈
      let layers = this.state.layers.filter(layer => layer.visible).reverse();
      for (const layer of layers) {
        const polygonsContainingPoint = getPolygonsWithinCoords(layer.data, coord);
        if (polygonsContainingPoint) {
          this.subtractData(polygonsContainingPoint, layer.id);
          this.setState({
            originalModifyPolygon: polygonsContainingPoint,
            editData: polygonsContainingPoint,
            layerBeingModified: layer,
            polygonBeingModified: polygonsContainingPoint,
            selectedFeatureIndexes: [0],
            displayMeasureToolTip: true,
            measureType: "area"
          })
          break;
        }
      }
    }, 10)
  }

  commitModify(commitWithoutChanges) {
    const { layerBeingModified, editData, polygonBeingModified, layers, originalModifyPolygon } = this.state;
    if (!commitWithoutChanges) {
      this.addData(editData, layerBeingModified.id);
    } else if (commitWithoutChanges) {
      this.addData(originalModifyPolygon, layerBeingModified.id)
    }
    this.setState({
      editData: createFc([]),
      layerBeingModified: null,
      polygonBeingModified: null,
      displayMeasureToolTip: false,
      selectedFeatureIndexes: []
    });

    if ((Math.abs(area(polygonBeingModified) - area(editData)) > 0.5) && !(commitWithoutChanges)) {
      // only push if there has been made substantial changes
      this.layerStack.push(layers)
    }

  }

  componentWillUnmount() {
    this.listener();
  }

  updatePaintBucket = () => {
    let layers = this.state.layers.filter(layer => layer.visible);
    try {
      let data = combineLayers(layers.map(layer => layer.data));
      this.setState({ visibleData: data });
      this.setEditingToASingleLayer(this.state.layerToBeEdited);
    } catch (error) {
      this.props.enqueueSnackbar("Something went wrong while updating the paint tool", { variant: "error", autoHideDuration: 2000 });
    }
  }

  setEditingToASingleLayer = (layerId) => {
    const { layers } = this.state;
    const newLayers = [...layers]
    newLayers.forEach(layer => {
      if (layer.id === layerId) {
        layer.editing = true;
        layer.visible = true;
      }
      else layer.editing = false;
    })
    this.setState({ layers: newLayers });
  }

  // create layer does not update the stack. It just creates a new layer object
  createLayer(data, layerName, defaultVisible = true, defaultEditing = false, layerType, hasBeenEdited = false, hidden = false) {
    this.setState({ amountOfLayers: this.state.amountOfLayers + 1 });

    const { companyName, cityName } = this.props;

    const hiddenLayers = localStorage.getItem(`${companyName}_${cityName}_hidden_layers`);
    if (hiddenLayers && hiddenLayers.length) {
      hidden = hiddenLayers.includes(layerName)
    }

    const visibleZones = localStorage.getItem(`${companyName}_${cityName}_visible_layers`);
    let visible = defaultVisible;
    if (visibleZones && visibleZones.length) {
      visible = visibleZones.includes(layerName) && !hidden;
    }

    const editingZones = localStorage.getItem(`${companyName}_${cityName}_editing_layers`);
    let editing = defaultEditing;
    if (editingZones && editingZones.length) {
      editing = editingZones.includes(layerName) && !hidden;
    }


    return { data, layerName, visible, editing, id: ++this.idCounter, layerType, hasBeenEdited, hidden };
  }

  mergeLayers = (layerNames, shouldRemove) => {
    const { layerToBeEdited, layers, layersToDelete } = this.state;
    const baseLayer = this.getLayer(layerToBeEdited);
    layerNames.forEach((name) => {
      const layer = this.getLayerFromName(name);
      baseLayer.data = union(layer.data, baseLayer.data);
      baseLayer.hasBeenEdited = true;
      if (shouldRemove) {
        layersToDelete.push(name);
      }
    })
    if (shouldRemove) this.layerStack.push(layers.filter(layer => !layersToDelete.includes(layer.layerName)));
    this.setShowMergeLayers(false);
    this.props.enqueueSnackbar("Succesfully merged layers", { variant: "success", autoHideDuration: 2000 });
  }

  mergeWithExistingData = (layerNames) => {
    const { dataToUpload, layers } = this.state;
    layerNames.forEach((name) => {
      const layer = this.getLayerFromName(name);
      layer.data = union(layer.data, dataToUpload);
      layer.hasBeenEdited = true;
    })
    this.layerStack.push(layers);
    this.setShowMergeLayers(false);
    this.setState({ dataToUpload: null })
    this.props.enqueueSnackbar("Succesfully merged file", { variant: "success", autoHideDuration: 2000 });
  }

  onDeleteCurrentLayer = () => {
    this.deleteLayer(this.state.currentDeletingLayer);
    this.setDeleteLayerDialogIsOpen(false);
  };

  deleteLayer = (layerId) => {
    let { layers } = this.state;
    layers = this.state.layers.filter(layer => layer.id !== layerId);
    if (layers.length === 0) {
      this.props.enqueueSnackbar("You must have at least 1 layer", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    this.layerStack.push(layers);
    this.state.layersToDelete.push(this.getLayer(layerId).layerName);
  }

  setCalculateDifferenceDialogOpen = isOpen => {
    this.setState({ calculateDifferenceDialogOpen: isOpen });
  }

  calculateDifference = (chosenLayers) => {
    const layersData = chosenLayers.map(layer => this.getLayerFromName(layer))
    let chosenOps = layersData.filter(layer => layer.layerType === "LAYER_TYPE_OPS");
    chosenOps = combineLayers(chosenOps.map(layer => layer.data))
    let chosenNpz = layersData.filter(layer => layer.layerType === "LAYER_TYPE_NPZ");
    chosenNpz = combineLayers(chosenNpz.map(layer => layer.data))
    const diff = subtract(chosenOps, chosenNpz);
    return diff;
  }

  setDeleteLayerDialogIsOpen = isOpen => this.setState({ alertDeleteLayerDialogIsOpen: isOpen });

  onSetDeleteLayer = layer => this.setState({ alertDeleteLayerDialogIsOpen: true, currentDeletingLayer: layer });

  onRemoveDeleteLayer = () => { this.setState({ alertDeleteLayerDialogIsOpen: false, currentDeletingLayer: null }) }

  generateCopyName = (orig) => {
    // this is really really bad. TODO: add numbers to allowed layer names
    let name = "copy of " + orig;
    for (let i = 0; i < 100; i++) {
      if (!this.isValidLayerName(name, true)) {
        name = "copy of " + name
        i++;
      } else return name;
    }
  }

  duplicateLayer = layerId => {
    const origLayer = this.getLayer(layerId)
    const { layers } = this.state;
    const layer = this.createLayer(createFc([]), origLayer.layerName, true, false, origLayer.layerType);
    layer.layerName = this.generateCopyName(origLayer.layerName);
    layer.hasBeenEdited = true;
    layer.data = origLayer.data
    layers.push(layer);
    this.layerStack.push(layers);
    this.setCreateDialogIsOpen(false);
    this.sortLayersOnType();
  }

  createCustomLayer = input => {
    if (!this.isValidLayerName(input.layerName)) {
      return;
    }
    const { layers } = this.state;
    const layer = this.createLayer(createFc([]), input.layerName, true, false, getLayerType(input.layerType));
    layer.layerName = input.layerName;
    layer.hasBeenEdited = true;
    if (this.state.dataToUpload) {
      layer.data = this.state.dataToUpload;
      this.setState({ dataToUpload: null })
    }
    layers.push(layer);
    this.layerStack.push(layers);
    this.setCreateDialogIsOpen(false);
    this.sortLayersOnType();
  }

  isValidLayerName(layerName, silent) {
    let errorMessage;
    if (!layerName) {
      errorMessage = "Please enter a layer name";
    }
    if (this.state.layers.filter(layer => layer.layerName === layerName).length !== 0) {
      errorMessage = "Please enter a unique layer name";
    }
    if (!(layerName.match(/([a-zA-Z0-9 ]*)/)[0] === layerName)) {
      errorMessage = "Layer name cannot contain special characters"
    }
    if (errorMessage) {
      if (!silent) {
        this.props.enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 2000 });
      }
      return false;
    }
    return true;
  }

  setCreateDialogIsOpen = isOpen => this.setState({ createLayerDialogIsOpen: isOpen });

  setLayerData = (editedLayers, snackbarMessage) => {
    let { layers, layersToDelete } = this.state;
    const toRemove = [];
    this.setState({ isLoading: true }, () => {
      setTimeout(() => {
        layers.forEach((layer) => {
          // if layer has been deleted
          if (!editedLayers.map((editedLayer) => editedLayer.id).includes(layer.id)) {
            toRemove.push(layer.id);
            return;
          }
          editedLayers.forEach(editedLayer => {
            if (layer.id === editedLayer.id) {
              layer.data = editedLayer.data;
              layer.layerName = editedLayer.layerName; // if rename
              layer.hasBeenEdited = editedLayer.hasBeenEdited
              layer.hidden = editedLayer.hidden
            }

            // if layer has been added
            if (!layers.map((layer) => layer.id).includes(editedLayer.id)) {
              layers.push(editedLayer)
              if (layersToDelete.includes(editedLayer.layerName)) {
                layersToDelete = layersToDelete.filter(name => name !== editedLayer.layerName);
                this.setState({layersToDelete})
              }
            }
          })
        });
        // set state and remove deleted layers
        layers = layers.filter(layer => !toRemove.includes(layer.id))
        this.setState({ layers }, () => {
          this.sortLayersOnType();
        });
        if (snackbarMessage)
          this.props.enqueueSnackbar(snackbarMessage, { variant: "info", autoHideDuration: 2000 })
        this.setState({ isLoading: false });
      }, 1);
    });
  }

  sortLayersOnType() {
    const { layers } = this.state;
    let sortOrder = ["LAYER_TYPE_OPS", "LAYER_TYPE_BOUNTY", "LAYER_TYPE_SSZ", "LAYER_TYPE_IPZ", "LAYER_TYPE_NPZ", "LAYER_TYPE_CUSTOM"];
    layers.sort(
      function (a, b) {
        if (a.layerType === b.layerType) {
          return a.layerName.localeCompare(b.layerName);
        } else {
          return sortOrder.indexOf(a.layerType) - sortOrder.indexOf(b.layerType);
        }
      });
    this.setState({ layers })
  }

  getLocation(layers) {
    // try to get center of operational zone if exists
    const opsLayers = layers.filter(layer =>
      layer.layerType === LAYER_TYPE_OPS
      && isValidFeatureCollection(layer.data));
    if (opsLayers.length > 0) {
      return center(opsLayers[0].data);
    }
    // return center of first valid layer
    else {
      layers = layers.filter(layer => isValidFeatureCollection(layer.data))
      if (layers.length > 0) return center(layers[0].data);
      else return point([9,48])
    }
  }

  async getZones() {
    if (this.props.isPlayground) this.initPlayground();
    else if (this.state.authUser) {
      if (!this.props.isPlayground) {
        const { layers } = this.state;
        this.setState({ isLoading: true });
        getCityZones(
          this.props.cityName,
          this.props.companyName,
          await this.state.authUser.getIdToken()
        ).then(zones => {
          zones.forEach((zone) => {
            let layerType = zone.layerType;
            if (!layerType) {
              layerType = getLayerType(zone.layerName);
            }
            let data = zone.data;
            if (!isValidFeatureCollection(data)) {
              data = createFc([]);
            }
            layers.push(this.createLayer(optimizeFeatures(data), zone.layerName, true, false, layerType))
          })
          this.sortLayersOnType();
          this.layerStack.push(this.state.layers);
          const location = this.getLocation(layers);
          this.setState({
            viewState: {
              ...this.state.viewState,
              latitude: location.geometry.coordinates[1],
              longitude: location.geometry.coordinates[0],
              zoom: 11,
              transitionDuration: 2000,
              transitionInterpolator: new FlyToInterpolator()
            },
            layerDidInit: true,
            isLoading: false,
            amountOfLayersSelected: 0
          });
        }).catch(() => {
          this.props.enqueueSnackbar("Something went wrong loading this city", { variant: "error", autoHideDuration: 20000 });
          this.setState({isLoading: false});
        });
      }
      this.showOnboardingIfUnseen();
      this.updateAmountOfLayersSelected();
    }
    else {
      console.error("You are not logged in!");
    }
  }

  initPlayground() {
    const { layers } = this.state;
    if (this.props.isDemo) {
      // this.setState({ showOnboarding: true })
      layers.push(this.createLayer(malmoNPZ, "No Parking Zones", true, true, LAYER_TYPE_NPZ));
      layers.push(this.createLayer(malmoOPS, "Operational Zone", true, false, LAYER_TYPE_OPS));
      layers.push(this.createLayer(malmoSSZ, "Slow Speed Zones", true, false, LAYER_TYPE_SSZ));
      const location = center(malmoOPS);
      this.setState({
        viewState: {
          ...this.state.viewState,
          latitude: location.geometry.coordinates[1],
          longitude: location.geometry.coordinates[0],
          zoom: 11,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator()
        }
      });
      // this.calculateDifference(this.state.layers);
    }
    else {
      this.state.layers.push(this.createLayer(createFc([]), "No Parking Zones", true, false, LAYER_TYPE_NPZ));
      this.state.layers.push(this.createLayer(createFc([]), "Operational Zone", true, false, LAYER_TYPE_OPS));
    }
    this.setState({ layerDidInit: true });
    this.layerStack.push(this.state.layers);
  }

  hasUnsavedChanges = () => {
    return this.layerStack.getSize() > 1 && !this.props.isPlayground
  }

  setDisplayHiddenLayers = () => {
    this.setState({displayHiddenLayers: !this.state.displayHiddenLayers}, this.sortLayersOnType)
  }

  setLayerToBeHidden = (id, value) => {
    const {layers} = this.state
    const newLayers = layers.map(layer => {
      if (layer.id === id) {
        layer.hidden = value
        layer.visible = !value
        layer.editing = !value
        return layer
      }
      else return layer
    });
    this.layerStack.push(newLayers);
    // hack because you cant callback with layerstack.push
    setTimeout(() => {
      this.updateLocalStorageWithLayerinfo();
    }, 1000);
  }

  saveZones = () => {
    if (this.state.polygonBeingModified) this.commitModify();
    let { layers, layersToDelete: deletedLayers } = this.state;
    if (!this.hasUnsavedChanges()) {
      this.props.enqueueSnackbar("Did not save. No changes have been made", { variant: "error" });
      return;
    }
    this.setState({ isSaving: true }, async () => {
      if (this.state.authUser) {
        try {
          await saveCityZones(
            this.props.cityName,
            this.props.companyName,
            layers,
            deletedLayers,
            await this.state.authUser.getIdToken()
          );
          this.props.enqueueSnackbar("Succesfully saved! 🎉", { variant: "success" });
          this.layerStack.clear();
        } catch (e) {
          this.props.enqueueSnackbar(
            'Something went wrong saving this city. Please try again. If error keeps occuring, then please download changed layers and contact hello@geowiz.tech',
            { variant: 'error', autoHideDuration: 30000 }
          );
          analytics.reportError(e);
        }
      } else {
        console.error("Did not save. You are not logged in");
        this.props.enqueueSnackbar("Did not save. You are not logged in", { variant: "error" });
      }
      this.setState({
        isSaving: false
      });
    });
  };

  downloadZones = async () => {
    if (this.state.polygonBeingModified) this.commitModify();
    const { layers } = this.state;
    layers.forEach((layer) => {
      this.downloadLayer(layer.id);
    })
  };

  downloadData = async (data, fileName, exportAsKml) => {
    data = getValidatedMultiPolygon(data);
    data = createFc([data]);
    try {
      if (data.type === "GeometryCollection") return // This means that the layer is empty should not try to download
      let blob
      if (exportAsKml) {
        data = prepareKMLdata(data);
        fileName = fileName + ".kml"
        blob = new Blob([tokml(data, {name: fileName})]);
      } else {
        fileName = fileName + ".geojson"
        blob = new Blob([JSON.stringify(data)], { type: "application/json;charset=utf-8" });
      }
      saveAs(blob, fileName);
    } catch (error) {
      console.error(`Couldn't downoad zones: ${error}`);
      this.props.enqueueSnackbar("Couldn't download zones", { variant: "error" });
    }
  }

  downloadDataAsFeatureCollection = async (data, fileName) => {
    let fc;
    try {
      switch (data.type) {
      case 'Polygon': {
        fc = convertPolygonToFeatureCollection(data);
        break;
      }
      case 'MultiPolygon': {
        fc = convertMultiPolygonToFeatureCollection(data);
        break;
      }
      default:
        throw new Error(`Couldn't convert type '${data.type}' into FeatureCollection`)
      }

      const blob = new Blob([JSON.stringify(fc)], { type: 'application/json;charset=utf-8' });
      saveAs(blob, `${fileName}.geojson`);
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  downloadLayer = async (id, { exportAsKml } = { exportAsKml: false }) => {
    const layer = this.getLayer(id)
    this.downloadData(layer.data, `${this.props.cityName}.${layer.layerName}`, exportAsKml)
  }

  handleDownloadLayer = async (layerId, downloadType) => {
    const layer = this.getLayer(layerId)

    if (downloadType === 'Polygon') {
      this.downloadDataAsFeatureCollection(layer.data, `${this.props.cityName}.${layer.layerName}`)
    } else {
      this.downloadData(layer.data, `${this.props.cityName}.${layer.layerName}`, false)
    }

    this.setShowDownloadLayerId(null)
  }

  addData(features, id) {
    const editedLayer = this.getLayer(id);
    editedLayer.data = union(editedLayer.data, features);
    editedLayer.hasBeenEdited = true;
  }

  subtractData(features, id) {
    const editedLayer = this.getLayer(id);
    editedLayer.data = subtract(editedLayer.data, features);
    editedLayer.hasBeenEdited = true;
  }

  setMapStyle = (mapStyle) => {
    this.setState({mapStyle})
  }

  hasSelectedLayers() {
    return this.state.layers.filter((layer) => layer.editing).length !== 0;
  }

  setOSMBoundingBox = (OSMBoundingBox) => {
    this.setState({ OSMBoundingBox })
  }

  _onEdit(updatedData, editType) {
    if (!ACCEPTABLE_TYPES.has(editType)) {
      return;
    }
    const { layers, editMode } = this.state;


    if (editMode === "modify") {
      this.setState({ editData: updatedData })
      return; // REMOVE
    }
    else if (editMode === 'osmPicker') {
      this.setShowOSMPicker(true);
      this.setOSMBoundingBox(updatedData);

    }
    else if (editMode === "measure") {
      if (updatedData.features.length) {
        this.setState({
          editData: updatedData,
          displayMeasureToolTip: getCoords(updatedData.features[0]).length > 1 //display tooltip, if more than 2 or more points have been added
        });
      }
    }
    else if (editType==="addFeature") {
      this.setState({ isLoading: true }, () => {
        setTimeout(() => { // Spinner doesn't get a width if we don't have a timeout.
          try {
            let successfullEdit = true;
            layers.forEach(layer => {
              if (layer.editing) {
                layer.hasBeenEdited = true;
                try {
                  const unkinked = unkinkPolygon(updatedData);
                  if (editMode === "addPolygon" || editMode ==="addRectangle" || editMode === "addCircle") {
                    this.addData(unkinked, layer.id);
                  } else if (editMode === "subPolygon" || editMode ==="subRectangle" || editMode === "subCircle" ) {
                    this.subtractData(unkinked, layer.id)
                  }
                } catch (error) {
                  console.error(
                    "👻 Couldn't edit data. Probably due to an invalid polygon",
                    error
                  );
                  successfullEdit = false;
                }
              }
            });
            this.setState({
              haveBeenEdited: true, isLoading: false,
            });
            if (successfullEdit) this.layerStack.push(layers);
          } catch (err) {
            analytics.reportError(err);
            this.props.enqueueSnackbar("Something went wrong. Please try again 🙈", { variant: 'info' });
          }
        }, 1);
      });
    }

  }

  handleGeoCoderViewStateChange = viewState => {
    const newViewState = {...viewState, transitionDuration: 1000} // default 3000, which takes way too long
    this.setState({
      viewState: newViewState
    });
  };

  _onViewStateChange({ viewState }) {
    this.setState({ viewState });
  }

  clone(object) {
    object = JSON.stringify(object);
    return JSON.parse(object);
  }

  _onEditDrawMode = (event, drawMode) =>
    this.setState({ drawMode });

  getLayerTypeFromName = (layerName) => {
    const layer = this.getLayerFromName(layerName)
    if (layer) return layer.layerType;
  }

  changeEditMode = (event, _editMode, callback) => {
    if (this.state.polygonBeingModified) this.commitModify();
    if (this.state.editMode === "measure") this.setState({ editData: createFc([]), displayMeasureToolTip: false })
    let { drawMode } = this.state;
    switch (_editMode) {
    case 'view':
    case 'paintBucket':
      drawMode = 'view';
      break;
    case 'addPolygon':
    case 'subPolygon':
      drawMode = 'drawPolygon';
      break;
    case 'modify':
      drawMode = 'modify';
      break;
    case "addRectangle":
    case "subRectangle":
      drawMode = 'drawRectangle';
      break;
    case "addCircle":
    case "subCircle":
      drawMode = "drawCircle";
      break;
    case 'osmPicker':
      drawMode = 'drawRectangle';
      break;
    case 'measure':
      drawMode = 'drawLineString';
      this.setState({ measureType: "distance" })
      break;
    default:
    }
    this.setState({ editMode: _editMode, drawMode}, callback);
  };

  _onLayerEditChange = id => {
    const { layers } = this.state;
    const layer = this.getLayer(id);
    if (layer.hidden) return;
    layer.editing = !layer.editing;
    if (layer.editing)
      layer.visible = true;
    this.setState({ layers }, this.updateLocalStorageWithLayerinfo);
    this.updateAmountOfLayersSelected();
  }

  updateAmountOfLayersSelected() {
    const { layers } = this.state;
    let count = 0;
    layers.forEach((layer) => {
      if (layer.editing) count++;
    })
    this.setState({ amountOfLayersSelected: count });
  }

  stopDrawing = () => {
    if (this.state.editMode === 'osmPicker') {
      this.changeEditMode(null, 'view');
      return;
    }
    if (this.state.polygonBeingModified)
    {
      this.commitModify(true);
      return;
    }
    if (this.state.editMode === "measure") {
      this.changeEditMode(null, 'view');
      this.setState({editData: createFc([])});
      return;
    }
    const oldMode = this.state.editMode;
    this.changeEditMode(null, 'view', () => this.changeEditMode(null, oldMode));

  };

  bufferLayer = (id, ratio) => {
    const layers = this.state.layers;
    layers.forEach((layer) => {
      if ((layer.id) === id) {
        let data = buffPolygons(layer.data, ratio);
        data = createFc(data);
        layer.data = data;
        this.layerStack.push(layers);
      }
    })
  }

  renderLayers() {
    const { layers } = this.state;
    const renderedLayers = [];
    layers.forEach(layer => {
      if (layer.visible) {
        renderedLayers.push(this.renderGeoLayer(layer));
      }
    });
    renderedLayers.push(this.renderEditableLayer());
    return renderedLayers;
  }

  getFillColor = () => {
    const {layerBeingModified, drawMode} = this.state;
    if (layerBeingModified) return getLayerFillColor(layerBeingModified.layerType);
    if (drawMode === "drawLineString") return [0, 0, 0, 255];
    return null;
  }

  getLineWidthMax = () => {
    const {drawMode} = this.state;
    if (drawMode === "drawLineString") return 3;
    return 2;
  }

  getLineWidthMin = () => {
    const {drawMode} = this.state;
    if (drawMode === "drawLineString") return 2;
    return 1;
  }

  translateDrawMode = (drawMode) => {
    switch (drawMode) {
    case "view":
      return ViewMode;
    case "drawPolygon":
      return DrawPolygonMode;
    case "modify":
      return ModifyMode;
    case "drawRectangle":
      return DrawRectangleMode;
    case "drawCircle":
      return DrawCircleFromCenterMode;
    case "measure":
      return MeasureDistanceMode;
    case "drawLineString":
      return DrawLineStringMode;
    default:
      console.log("No matching drawmode for " + drawMode);
      return ViewMode;
    }
  }

  renderEditableLayer() {
    const { editData, drawMode, selectedFeatureIndexes } = this.state;
    const translatedDrawMode = this.translateDrawMode(drawMode);

    return new EditableGeoJsonLayer({
      id: `editLayer`,
      key: `editLayer`,
      data: editData,
      selectedFeatureIndexes: selectedFeatureIndexes,
      mode: translatedDrawMode,
      editHandlePointRadiusScale: 0.1,
      lineWidthMinPixels: this.getLineWidthMin(),
      lineWidthMaxPixels: this.getLineWidthMax(),
      getRadius: 20,
      getFillColor: this.getFillColor(),
      getLineColor: [0, 0, 0, 255],
      onEdit: ({ updatedData, editType }) => {
        this._onEdit(updatedData, editType);
      }

    });
  }

  renderGeoLayer(layer) {
    return new GeoJsonLayer({
      id: `${layer.id}_data`,
      key: layer.id,
      data: layer.data,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      lineWidthScale: 0.1,
      lineWidthMinPixels: 1,
      getFillColor: getLayerFillColor(layer.layerType),
      getLineColor: getLayerLineColor(layer.layerType),
      getRadius: 100,
      getLineWidth: 3,
    });
  }

  renderLayerControls() {
    const { layers, displayHiddenLayers } = this.state;
    return layers.filter(x => displayHiddenLayers? x : !x.hidden).map(layer => (
      <LayerControls
        key={layer.id}
        layer={layer}
        editMode={layer.editMode}
        changeEditMode={this.changeEditMode}
        onVisibilityChange={this._onVisibilityChange}
        onLayerEditChange={this._onLayerEditChange}
        onDeleteLayer={this.deleteLayer}
        onDownloadClick={this.setShowDownloadLayerId}
        onRenameClick={this.setShowRenameDialog}
        onDeleteClick={this.deleteLayer}
        onDuplicateLayer={this.duplicateLayer}
        setLayerToBeEdited={this.setLayerToBeEdited}
        setShowOSMPicker={this.setShowOSMPicker}
        setShowMergeLayers={this.setShowMergeLayers}
        setShowTransformations={this.setShowTransformations}
        setLayerToBeHidden={this.setLayerToBeHidden}
        hiddenLayers={this.state.hiddenLayers}
        onInfoClick={this.setShowInfo}
      />
    ))
  }

  renameLayer = (newName) => {
    if (!this.isValidLayerName(newName)) {
      return;
    }
    const id = this.state.layerToBeEdited;
    const { layers } = this.state;
    layers.forEach((layer) => {
      if ((layer.id) === id) {
        this.state.layersToDelete.push(layer.layerName);
        layer.layerName = newName;
        layer.hasBeenEdited = true;
      }
    })
    this.layerStack.push(layers);
    this.setShowRenameDialog(false);
    this.props.enqueueSnackbar("Layer renamed!", { variant: "success" })
  }

  renderLayerControlsList() {
    if (this.state.layerDidInit && this.state.showLayersPanel) {
      return (
        <LayerControlsList
          layerControls= {this.renderLayerControls()}
          onDialogIsOpen={this.setCreateDialogIsOpen}
          amountOfLayersSelected={this.state.amountOfLayersSelected}
          amountOfLayers={this.state.amountOfLayers}
          cityName={this.props.cityName}
          setDisplayHiddenLayers = {this.setDisplayHiddenLayers}
          displayHiddenLayers={this.state.displayHiddenLayers}
        >
        </LayerControlsList>
      )
    }
  }

  renderMapOptions() {
    if (this.state.layerDidInit) {
      return (
        <div className="hideInSS" style={{position: "absolute", right: 10, zIndex: 10}} >
          <MapOptions
            undo={() => this.layerStack.undo()}
            redo={() => this.layerStack.redo()}
            canUndo={() => this.layerStack.canUndo()}
            canRedo={() => this.layerStack.canRedo()}
            layerStack={this.layerStack}
            current={this.layerStack.current}
            style={{ display: "flex", pointerEvents: 'auto' }}
            isPlayground={this.props.isPlayground}
            onSaveClick={this.saveZones}
            layers={this.state.layers}
            setShowDownloadOnMergeDialog={this.setShowDownloadOnMergeDialog}
            geoCoderContainerRef={this.geoCoderContainerRef}
          />
        </div>
      )
    }
  }

  renderRuler() {
    if (this.state.layerDidInit) {
      return (
        <RulerTool
          selected={this.state.editMode === "measure"}
          setIsSelected={() => {
            if (this.state.editMode === "measure") {
              this.changeEditMode(null, "view")
            } else {
              this.changeEditMode(null, "measure")
            }
          }}
        />
      )
    }
  }

  showOnboardingIfUnseen = () => {
    const onboardingSeen = localStorage.getItem('onboardingSeen');
    if (!onboardingSeen) {
      this.setState({ showOnboarding: true });
      localStorage.setItem('onboardingSeen', true);
    }
  }

  renderCreateLayerDialog() {
    return (
      <CreateLayerDialog
        isOpen={this.state.createLayerDialogIsOpen}
        submitInput={this.createCustomLayer}
        title={"Create Layer"}
        content={"What should the name of your layer be?"}
        onDialogIsOpen={this.setCreateDialogIsOpen}
        buttonText="Create Layer"
      ></CreateLayerDialog>
    )
  }

  onMapClick = ({ coordinate }) => {
    const { editMode } = this.state;
    switch (editMode) {
    case "paintBucket":
      this.paint(coordinate);
      break;
    case "modify":
      this.startModifying(coordinate)
      break;
    case "addPolygon":
    case "subPolygon":
    case "addRectangle":
    case "subRectangle":
      if (!this.hasSelectedLayers()) {
        this.props.enqueueSnackbar("There are no layers selected", { variant: 'error' });
        this.stopDrawing();
      }
      break;
    default:
    }
  }


  paint = (coordinate) => {
    this.setState({ isLoading: true }, () => {
      setTimeout(() => { // Spinner doesn't get a width if we don't have a timeout.
        const { visibleData, layerToBeEdited } = this.state;
        try {
          const fc = getFeatureToFill(visibleData.features, coordinate);
          const layers = this.state.layers;
          const layer = this.getLayer(layerToBeEdited);
          layer.data = union(this.getLayer(layerToBeEdited).data, fc);
          layer.hasBeenEdited = true;
          layer.data = removeSmallHoles(layer.data);
          this.layerStack.push(layers);
        } catch (error) {
          this.props.enqueueSnackbar(error.message, { variant: "error", autoHideDuration: 4000 });
        }
        this.setState({ isLoading: false })
      }, 1
      )
    })
  }


  renderUploadFileBox() { }

  getCursor = ({ isDragging }) => {
    const { editMode } = this.state;
    switch (editMode) {
    case 'paintBucket':
      return `url(${bucketSVG}), auto`;
    case 'addPolygon':
    case 'subPolygon':
    case 'osmPicker':
    case "addRectangle":
    case "subRectangle":
    case "addCircle":
    case "measure":
      return 'crossHair';
    case 'view':
      return isDragging ? 'grabbing' : 'grab';
    case 'modify':
      return this.state.polygonBeingModified ? 'default' : `url(${EditSVG}), auto`;
    default:
    }
  }

  mergeOnDownload = (layersToDownload, exportAsKml) => {
    Object.keys(layersToDownload).forEach((layerType) => {
      const names = layersToDownload[layerType];
      if (names.length === 0) return;
      let data;
      if (names.length > 1) {
        data = combineLayers(names.map(name => this.getLayerFromName(name).data));
        this.downloadData(data, `${this.props.cityName}.${layerType}`, exportAsKml)
      } else {
        this.downloadLayer(this.getLayerFromName(names[0]).id, { exportAsKml });
      }
    })
  }


  setLayerToBeEdited = (id) => {
    this.setState({ layerToBeEdited: id })
  }

  setShowLayersPanel = (showLayersPanel) => {
    this.setState({ showLayersPanel });
  }


  uploadCustomGeoJson(geoJson, layerId) {
    if (geoJson.features.length === 0) { // should use a validator method instead
      this.props.enqueueSnackbar("No data available", { variant: "error", autoHideDuration: 4000 });
      return;
    }
    const { layers } = this.state;
    const layer = this.getLayer(layerId);
    layer.hasBeenEdited = true;
    layer.data = unionToFc(geoJson, layer.data);
    this.setState({ layers, isLoading: false }, () => {
      this.props.enqueueSnackbar("Successfully uploaded file.", { variant: "success", autoHideDuration: 4000 });
    });
    this.layerStack.push(this.state.layers);
  }

  setAddTypeShape = (addTypeShape) => {
    this.setState({addTypeShape})
  }

  setSubTypeShape = (subTypeShape) => {
    this.setState({subTypeShape})
  }

  setShowUploadDialog = (showUploadDialog) => {
    this.setState({ showUploadDialog });
  }

  getFeatureDescription = () => {
    const {editMode, showOSMpicker, polygonBeingModified} = this.state;
    if (editMode === "osmPicker" && !showOSMpicker) {
      return "Draw a rectangle to fetch OSM"
    }
    else if (editMode === "modify" && polygonBeingModified) {
      return "'Esc' to abort, 'Enter' to commit changes"
    }
    return "";

  }

  onCitiesClick = () => {
    this.props.history.push("/home/cities/" + this.props.companyName);
  }

  mapRef = React.createRef();
  geoCoderContainerRef = React.createRef();

  renderGeoCoder = () => {
    if (!this.geoCoderContainerRef.current) return null;
    return (
      <Geocoder
        mapRef={this.mapRef}
        containerRef={this.geoCoderContainerRef}
        onViewportChange={this.handleGeoCoderViewStateChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
        position="bottom-right"
      />
    )
  }

  setDisplayMDSOverview = (val) => {
    this.setState({displayMDSOverview: val})
  }

  render() {
    const layers = this.renderLayers();
    return (
      <React.Fragment>
        <Joyride
          run={this.state.showOnboarding}
          steps={this.props.isDemo? this.state.joyrideSteps.demo : this.state.joyrideSteps.standard }
          showProgress
          continuous
          styles={
            {options: {
              zIndex: 9999,
              arrowColor: '#495bbe',
              primaryColor: '#495bbe',
            }}
          }
        >

        </Joyride>
        <FeatureDescription
          visible = {this.getFeatureDescription() !== ""}
          text={this.getFeatureDescription()}
        />
        {this.renderMapOptions()}
        <Toolbar
          shouldDisplayMDS={this.state.shouldDisplayMDS}
          setDisplayMDSOverview={this.setDisplayMDSOverview}
          onCitiesClick={this.onCitiesClick}
          companyName={this.props.companyName}
          isPlayground={this.props.isPlayground}
          onSaveClick={this.saveZones}
          editMode={this.state.editMode}
          changeEditMode={this.changeEditMode}
          layers={this.state.layers}
          setLayerToBeEdited={this.setLayerToBeEdited}
          updatePaintBucket={this.updatePaintBucket}
          setBucketOpen={(value) => this.setState({ bucketOpen: value })}
          bucketOpen={this.state.bucketOpen}
          bucketMenuPos={this.state.bucketMenuPos}
          setShowDownloadOnMergeDialog={this.setShowDownloadOnMergeDialog}
          setShowUploadDialog={this.setShowUploadDialog}
          setShowOSMPicker={this.setShowOSMPicker}
          setDrawMode={this.setDrawMode}
          setCalculateDifferenceDialogOpen={this.setCalculateDifferenceDialogOpen}
          setAddTypeShape={this.setAddTypeShape}
          setSubTypeShape={this.setSubTypeShape}
        />
        <ToggleLayersButton layerDidInit={this.state.layerDidInit} showLayersPanel={this.state.showLayersPanel} setShowLayersPanel={this.setShowLayersPanel}></ToggleLayersButton>
        <div style={{
          pointerEvents: 'none',
          zIndex: 8, // toolbar is 9
          position: "absolute",
          width: this.state.showLayersPanel ? "" : 130,
          borderRadius: 20,
          height: "100vh",
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 0,
          paddingLeft: 80
        }}
        className="hideInSS"
        >
          {this.renderLayerControlsList()}
          {this.renderCreateLayerDialog()}
        </div>
        <div style={{
          zIndex: 1,
          position: "absolute",
          bottom: 0,
          right: 5,
        }}
        >
          <StyleToggler
            setMapStyle={this.setMapStyle}
            mapStyle={this.state.mapStyle}
            toggleMapStyle={this.toggleMapStyle}
            mapStyles={mapStyles}
          />
        </div>
        {this.renderRuler()}
        <div style={{
          zIndex: 1,
          position: "absolute",
          bottom: 140,
          right: 10,
        }}
        >
          {this.state.isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: this.props.theme.spacing(1),
                padding: this.props.theme.spacing(1)
              }}
            >
              <CircularProgress disableShrink />
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.isSaving ? (
            <div
              style={{
                zIndex: 1,
              }}
            >
              <SaveAlert isSaving={this.state.isSaving} setIsSaving={this.setIsSaving} />
            </div>
          ) : null}
        </div>

        {this.state.displayMDSOverview && <MDSViewer
          cityName={this.props.cityName}
          authUser={this.state.authUser}
          downloadData={this.downloadData}
          isOpen={this.state.displayMDSOverview}
          setDisplayMDSOverview={this.setDisplayMDSOverview}
        />}

        <OSMPicker
          layerToBeEdited={this.state.layerToBeEdited}
          onSubmit={fetchOSMData}
          uploadCustomGeoJson={this.uploadCustomGeoJson}
          layers={this.state.layers}
          open= {this.state.showOSMpicker}
          setShowOSMPicker={this.setShowOSMPicker}
          OSMBoundingBox={this.state.OSMBoundingBox}
          changeEditMode={this.changeEditMode}
        />

        <TransformationsDialog
          setShowTransformations={this.setShowTransformations}
          showTransformations={this.state.showTransformations}
          layers={this.state.layers}
          layerToBeEdited={this.state.layerToBeEdited}
          bufferLayer={this.bufferLayer}
        />

        <InfoDialog
          setShowInfoDialog={this.setShowInfo}
          showInfoDialog={this.state.showInfoDialog}
          getLayer={() => this.getLayer(this.state.layerToBeEdited)}
        />

        <CalculateDifferenceDialog
          setCalculateDifferenceDialogOpen={this.setCalculateDifferenceDialogOpen}
          calculateDifferenceDialogOpen={this.state.calculateDifferenceDialogOpen}
          layers={this.state.layers}
          onSubmit={this.calculateDifference}
          getLayerType = {this.getLayerTypeFromName}
        />

        {!!this.state.showDownloadLayerId && (
          <DownloadLayerDialog
            isOpen={!!this.state.showDownloadLayerId}
            layerId={this.state.showDownloadLayerId}
            onClose={() => this.setShowDownloadLayerId(null)}
            onDownload={this.handleDownloadLayer}
          />
        )}

        <MergeLayerDialog
          showMergeLayers={this.state.showMergeLayers}
          dataToUpload={this.state.dataToUpload}
          layerToBeEdited={this.state.layerToBeEdited}
          layers={this.state.layers}
          getLayer={this.getLayer}
          setShowMergeLayers={this.setShowMergeLayers}
          mergeWithExistingData={this.mergeWithExistingData}
          mergeLayers={this.mergeLayers}
        />

        {this.state.showDownloadOnMergeDialog ? (
          <div
            style={{
              zIndex: 1,
            }}
          >
            <DownloadOnMergeDialog
              layers={this.state.layers}
              setShowDownloadOnMergeDialog={this.setShowDownloadOnMergeDialog}
              showDownloadOnMergeDialog={this.state.showDownloadOnMergeDialog}
              submitInput={this.mergeOnDownload}
              getLayerFromName={this.getLayerFromName}
            />
          </div>
        ) : null}
        {this.state.showRenameDialog ? (
          <div
            style={{
              zIndex: 1,
            }}
          >
            <RenameDialog layerName={this.getLayer(this.state.layerToBeEdited).layerName} layerToBeEdited={this.state.layerToBeEdited} renameLayer={this.renameLayer} setShowRenameDialog={this.setShowRenameDialog} open={this.state.showRenameDialog} />
          </div>
        ) : null}

        <UploadDialog
          setCreateDialogIsOpen={this.setCreateDialogIsOpen}
          setDataToUpload={this.setDataToUpload}
          setShowUploadDialog={this.setShowUploadDialog}
          showUploadDialog={this.state.showUploadDialog}
          setShowMergeLayers={this.setShowMergeLayers} >
        </UploadDialog>
        <MeasureToolTip visible={this.state.displayMeasureToolTip} editData={this.state.editData} measureType={this.state.measureType}/>

        <DeckGL
          layers={layers}
          initialViewState={this.state.viewState}
          controller={true}
          getCursor={this.getCursor}
          useDevicePixels={true}
          onClick={this.onMapClick}
        >
          <ReactMapGL
            getMap
            ref={this.mapRef}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
            mapStyle={this.state.mapStyle}
          >
            {this.renderGeoCoder()}
            <div
              className='mapboxgl-ctrl-bottom-right'
              style={{marginBottom: 100, marginRight: 22}}
            />
          </ReactMapGL>
        </DeckGL>
      </React.Fragment>
    );
  }
}

Map.propTypes = {
  cityName: PropTypes.string.isRequired,
  companyName: PropTypes.string
};

export default withRouter(withSnackbar(withTheme(withFirebase(Map))));
