import React, { PureComponent } from "react";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, Typography, DialogContent} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";



class SaveAlert extends PureComponent {

  handleClose = () => {
    this.props.setIsSaving(false);
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          padding={2}
          style={{
            margin: this.props.theme.spacing(2),
            padding: this.props.theme.spacing(2),
            textAlign: "center",
          }}
          open={this.props.isSaving}
          onClose={this.handleClose}
        >
          <DialogContent>
            <Typography
              variant={"h6"}
              style={{
                marginBottom: this.props.theme.spacing(2),
              }}>
              Saving... Please wait!
            </Typography>
            <CircularProgress
              disableShrink
              size={36} 
              style={{marginBottom: "4px"}}/>
          </DialogContent>
        </Dialog>
      </React.Fragment >
    );
  }
}

export default withTheme(SaveAlert);
