/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { withTheme } from "@material-ui/core/styles";
import ReactDOM from "react-dom";


class LayerMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      node: undefined
    }
  }

  handleClick = event => {
    event.stopPropagation();
    this.setState({ open: true })
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ open: false })
  };

  handleDownload = event => {
    this.props.onDownloadClick(this.props.layerId);
    event.stopPropagation();
  };

  handleDelete = event => {
    this.props.onDeleteClick(this.props.layerId);
    event.stopPropagation();
  };

  handleRename = event => {
    this.props.setLayerToBeEdited(this.props.layerId);
    this.props.onRenameClick(true);
    event.stopPropagation();
  };

  handleMergeLayer = event => {
    event.stopPropagation();
    this.props.setLayerToBeEdited(this.props.layerId);
    this.props.setShowMergeLayers(true);
  }

  handleTransformations = event => {
    event.stopPropagation();
    this.props.setLayerToBeEdited(this.props.layerId);
    this.props.setShowTransformations(true);
  }

  handleHideLayer = event => {
    event.stopPropagation();
    this.props.setLayerToBeHidden(this.props.layerId, !this.props.hidden);
  }

  handleDuplicateLayer = event => {
    event.stopPropagation();
    this.props.onDuplicateLayer(this.props.layerId)
  }

  handleInfoClick = event => {
    event.stopPropagation();
    this.props.setLayerToBeEdited(this.props.layerId);
    this.props.onInfoClick(true)
  }

  componentDidMount() {
    this.setState({
      node: ReactDOM.findDOMNode(this)
    })
  }

  render() {
    const {hidden} = this.props;
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          open={this.state.open}
          anchorEl={this.state.node}
          keepMounted
          onClose={this.handleClose}
        >
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleDownload}> Download </MenuItem>
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleDelete}> Delete layer </MenuItem>
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleRename}> Rename layer </MenuItem>
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleMergeLayer}> Merge layer </MenuItem>
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleDuplicateLayer}> Duplicate layer </MenuItem>
          <MenuItem style={{display: hidden && "none"}} onClick={this.handleTransformations}> Transformations </MenuItem>
          <MenuItem onClick={this.handleHideLayer}> {this.props.hidden ? "Activate Layer (for me)" : "Disable Layer (for me)"} </MenuItem>
          <MenuItem onClick={this.handleInfoClick}>Info</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withTheme(LayerMenu);
