import app from "firebase/app";
import "firebase/auth";

// Should probably not be on git but 🤷‍️🤷‍
const config = {
  apiKey: "AIzaSyA1-1tMOCMgAXkHxuVQxcTWDXU_8y76X4Y",
  authDomain: "geowiz-prod.firebaseapp.com",
  databaseURL: "https://geowiz-prod.firebaseio.com",
  projectId: "geowiz-prod",
  storageBucket: "geowiz-prod.appspot.com",
  messagingSenderId: "627350715330",
  appId: "1:627350715330:web:f97c694024abdb07c9345b"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
  }

  // *** Auth API ***

  doSignOut = () => this.auth.signOut();

  // Email login
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
}

export default Firebase;
