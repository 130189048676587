import React from "react"
import { ToggleButton } from "@material-ui/lab"
import RulerIcon from "./../../assets/icons/ruler.png"

export default function RulerTool({selected, setIsSelected}) {
  return (
    <div style={{
      zIndex: 1,
      position: "absolute",
      bottom: 100,
      right: 33,
    }}
    className="hideInSS"
    >
      <ToggleButton
        style={{
          backgroundColor: selected? "#e6e6e6" : "white",
          paddingRight: 4,
          paddingTop: 4,
          paddingLeft: 4,
          paddingBottom: 4,
          height: 32,
          borderWidth: 0,
          boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.1)"

        }}
        value="check"
        selected={selected}
        onChange={setIsSelected}
      >
        <img
          alt="remove"
          src={RulerIcon}
          style={{
            width: "22px"
          }}></img>
      </ToggleButton>
    </div>)
}