import React from "react";
import CityList from "./CityList";

const CityOverview = props => {
  const { cities, company } = props;
  return (
    <React.Fragment>
      <div
        style={{
          height: "100%",
          maxWidth: 1233,
        }}
      >
        <CityList cities={sortCities(cities)} company={company} hideEditHistory center/>
      </div>
    </React.Fragment>
  );
};

function sortCities(cities) {
  if (cities.length < 2) return cities;
  cities.sort((a,b) => {
    if (a.locked === b.locked) return 0;
    else if (a.locked && !b.locked) return 1;
    else if (!a.locked && b.locked) return -1;
    else return 0;
  })
  return cities
}


export default CityOverview;
