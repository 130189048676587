import React from 'react';
const JoyRideSteps =
{
  standard: [
    {
      content: <div><h2>Welcome to Geowiz!</h2> <div>{"Here's a quick tour to get you started "}<span role="img" aria-label="clapping">&#128079;</span></div></div>,
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: "These are your layers. Just click on them to select them",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'left',
      target: '.layerBox',
      title: "Layers"
    },
    {
      content: "These are your editing tools. You can use them to draw, erase and even import zones!",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.tools',
      title: "Tools"
    },
    {
      content: "When you are done editing, you can download your zones here",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.downloadButton',
      title: "Download"
    },
    {
      content: "Don't forget to save before you exit!",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.saveButton',
      title: "Save"
    },
    {
      content: <div>For more information, check out our <a href="http://faq.geowiz.tech/" rel="noopener noreferrer" target="_blank">FAQ</a></div>,
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
      title: "More information"
    }
  ],
  demo: 
  [
    {
      content: <div><h2>Welcome to Geowiz!</h2> <div>{"Here's a quick tour to get you started "}<span role="img" aria-label="clapping">&#128079;</span></div></div>,
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: "These are your layers. Just click on them to select them",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'left',
      target: '.layerBox',
      title: "Layers"
    },
    {
      content: "These are your editing tools. You can use them to draw, erase and even import zones!",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.tools',
      title: "Tools"
    },
    {
      content: "When you are done editing, you can download your zones here",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.downloadButton',
      title: "Download"
    },
    {
      content: "Don't forget to save before you exit! (disabled in Demo)",
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.saveButton',
      title: "Save"
    },
    {
      content: <div>For more information, check out our <a href="http://faq.geowiz.tech/" rel="noopener noreferrer" target="_blank">FAQ</a></div>,
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
      title: "More information"
    }
  ]
}


export default JoyRideSteps;
