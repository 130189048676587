import React from "react";
import CustomDialog from "./CustomDialog";
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import CategoryIcon from '@material-ui/icons/Category';
import FunctionsIcon from '@material-ui/icons/Functions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { area } from "@turf/turf";
import { createFc, getGeosBoundless } from "../../utils/geo/operations";

export default function InfoDialog(props) {
  const { setShowInfoDialog, showInfoDialog, getLayer } = props;

  if (!showInfoDialog) return null;

  const getArea = layer => {
    try {
      const polygon = createFc([layer.data]).features[0];
      const a = area(polygon) * 0.000001;
      return ("" + a).slice(0, 5) + " km2";
    } catch (err) {
      console.log(err);
      return "ERROR"
    }
  }

  const getPolygons = layer => {
    try {
      const polygon = createFc([layer.data]).features[0];
      const totalPolygons = getGeosBoundless(polygon).Polygons.length;
      return totalPolygons 
    } catch (err) {
      console.log(err);
      return "ERROR"
    }
  }
    
  const getAverageArea = layer => {
    try {
      const polygon = createFc([layer.data]).features[0];
      const totalPolygons = getGeosBoundless(polygon).Polygons.length;
      const totalArea = area(polygon);
      const avgArea = Math.floor(totalArea / totalPolygons) || 0
      return "" + avgArea + " m2";
    } catch (err) {
      console.log(err);
      return "ERROR"
    }
  }

  const renderDialogContent = () => (
    <List style={{
         
    }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <SquareFootIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Total area" secondary={getArea(getLayer())} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FunctionsIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Average area" secondary={getAverageArea(getLayer())} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CategoryIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Polygons" secondary={getPolygons(getLayer())} />
      </ListItem>
    </List>
  )

  return (
    <CustomDialog
      open={showInfoDialog}
      onClose={setShowInfoDialog}
      renderDialogContent={renderDialogContent}
      renderDialogActions={() => (<React.Fragment />)}
      title={"Layer info"}
    />
  )
}
