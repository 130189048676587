import React, { PureComponent } from "react";
import { withTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

class ToggleLayersButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }

  render() {
    const { hover } = this.state;
    if (!this.props.layerDidInit) return null;
    return (
      <div className="hideInSS">
        {this.props.showLayersPanel ?
          <Button
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            onClick={() => this.props.setShowLayersPanel(false)}
            size = {'small'}
            variant='contained' style={{
              zIndex: 10,
              position: 'absolute',
              left: hover ? 375  : 425,
              top: 25,
              backgroundColor: hover ? "#4a5bbb" : 'white',
              borderRadius: hover ? 30 : "50%",
              minWidth: hover ? 90 : 45,
              height: 45,
              color: hover ? "white" : "black",
              paddingRight: hover ? 50 : 5,
            }}>
            <ArrowBackIos fontSize={'small'}/>
          </Button> :
          <Button
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            onClick={() => this.props.setShowLayersPanel(true)}
            size = {'small'}
            variant='contained' style={{
              zIndex: 10,
              position: 'absolute',
              left: 110,
              top: 25,
              backgroundColor: hover ? "#4a5bbb" : 'white',
              borderRadius: hover ? 30 : "50%",
              minWidth: hover ? 90 : 45,
              height: 45,
              color: hover ? "white" : "black",
              paddingLeft: hover ? 50 : 10
            }}>
            <ArrowForwardIosIcon fontSize ={'small'}/>
          </Button>
        }
      </div>
    );
  }
}

export default withTheme(ToggleLayersButton);
