import React from 'react';
import { Redirect } from 'react-router'
import * as ROUTES from '../../constants/routes';

const LandingPage = () => (
  <div>
    <h1>LandingPage</h1>
    <Redirect to={ROUTES.SIGN_IN} />
  </div>
);

export default LandingPage;
