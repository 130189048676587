export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const PASSWORD_FORGET = '/pw-forget';
export const LICENSES = '/licenses';

export const HOME = '/home';
export const HOME_CITIES = '/home/cities/:companyName';
export const HOME_CITIES_REDIRECT = '/home/cities';
export const EDITOR = '/home/editor/:companyName/:cityName';
export const PLAYGROUND_EDITOR = '/playground';
export const PLAYGROUND_DEMO = '/demo';
export const ADMIN_PANEL = '/admin/:companyName';
