import React from "react";
import CityListItemEntry from "./CityListItemEntry";
import Grid from "@material-ui/core/Grid";

const CityList = props => {
  const { cities, company, hideEditHistory, center } = props;
  let cityAlign = center ? "center" : "none";
  return (
    <React.Fragment>
      <Grid container justify={cityAlign} alignItems={cityAlign}>
        {cities.map(city =>
          buildListItem(
            company,
            city.name,
            city.locked,
            hideEditHistory ? null : city.editHistory
          )
        )}
      </Grid>
    </React.Fragment>
  );
};

function buildListItem(company, city, locked, editHistory) {
  return (
    <Grid key={city} item>
      <CityListItemEntry
        key={city}
        companyName={company}
        cityName={city}
        locked={locked}
        editHistory={editHistory}
      />
    </Grid>
  );
}
export default CityList;
