import React, { PureComponent } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Map from "./Map";
import { withFirebase } from "../Firebase";

class Editor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline></CssBaseline>
        <main>
          {this.props.isPlayground ? (
            <Map cityName={this.props.isDemo? "Malmo" : "Playground"} isPlayground isDemo={this.props.isDemo}/>
          ) :
            <Map cityName={this.props.match.params.cityName} companyName={this.props.match.params.companyName} isPlayground={false}/>
          }
        </main>
      </React.Fragment>
    );
  }
}

export default withFirebase(Editor);