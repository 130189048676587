import React, { Component } from "react";
import {
  DialogActions,
  Button,
  TextField,
  FormControl
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withTheme } from "@material-ui/core/styles";
import CustomDialog from "./CustomDialog";

class TransformationsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratio: 0,
      transformationType: "Transformation"
    };
  }

  handleRatioChange = event => {
    this.setState({ ratio: event.target.value });
  };

  handleTypeChange = event => {
    this.setState({ transformationType: event.target.value });
  };

  renderDialogContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 32,
          paddingLeft: 32,
          paddingRight: 32
        }}
      >
        <FormControl style={{ display: "flex" }}>
          <Select
            value={this.state.transformationType}
            onChange={this.handleTypeChange}
            displayEmpty
            style={{ minWidth: 150, maxWidth: 150 }}
          >
            <MenuItem value="Transformation" disabled>
              Transformation
            </MenuItem>
            <MenuItem value="Buffer">Buffer</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ display: "flex" }}>
          <TextField
            style={{ minWidth: 150, maxWidth: 150, marginBottom: 20 }}
            autoFocus
            label={
              this.state.transformationType === "Buffer" ? "Meters" : "Ratio"
            }
            autoComplete={"off"}
            margin="dense"
            id="name"
            onChange={this.handleRatioChange}
          ></TextField>
        </FormControl>
      </div>
    );
  };

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2)
        }}
      >
        <Button
          variant="contained"
          disabled={this.state.transformationType === "Transformation"}
          style={{
            backgroundColor:
              this.state.transformationType === "Transformation"
                ? null
                : "#4a5bbb",
            color:
              this.state.transformationType === "Transformation"
                ? null
                : "white"
          }}
          onClick={() => {
            if (this.state.transformationType === "Buffer") {
              this.props.bufferLayer(
                this.props.layerToBeEdited,
                this.state.ratio
              );
            }
            this.props.setShowTransformations(false);
          }}
        >
          Transform
        </Button>
      </DialogActions>
    );
  };

  getLayerName(id) {
    try {
      let { layers } = this.props;
      layers = layers.filter(layer => layer.id === id);
      return layers[0].layerName;
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (!this.props.showTransformations) return null;
    const { setShowTransformations, showTransformations } = this.props;
    return (
      <CustomDialog
        open={showTransformations}
        onClose={setShowTransformations}
        renderDialogActions={this.renderDialogActions}
        renderDialogContent={this.renderDialogContent}
        title={"Transform Layer"}
        subTitle={
          "Select transformation type and ratio for '" +
          this.getLayerName(this.props.layerToBeEdited) +
          "'"
        }
      />
    );
  }
}

export default withTheme(TransformationsDialog);
