import React, { Component } from 'react';
import {
  Dialog, DialogActions, DialogContent, Checkbox, Typography, IconButton
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import LayerTag from "./LayerTag";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';




class CustomDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      additiveLayerName: "",
      additiveLayerId: null,
      checkedLayers: []
    }
  }

  handleAdditiveLayerChange = event => {
    const additiveLayer = this.props.layers.filter(layer => layer.layerName === event.target.value)[0];
    this.setState({ additiveLayerName: additiveLayer.layerName, additiveLayerId: additiveLayer.id })
  }

  renderSelectionItems() {
    const layers = this.props.layers.filter(layer => layer.id !== this.props.layerToBeEdited);
    return layers.map(layer => (
      <div key={layer.layerName} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox key={layer.layerName} color="primary" id={layer.layerName} onChange={() => {
          const { checkedLayers } = this.state;
          if (checkedLayers.includes(layer.layerName)) {
            checkedLayers.splice(checkedLayers.indexOf(layer.layerName), 1);
            this.setState({ checkedLayers });
            return;
          }
          checkedLayers.push(layer.layerName);
        }}>
        </Checkbox>
        <LayerTag key={layer.layerName} editing layerType={layer.layerType}></LayerTag>
        <Typography key={layer.layerName} style={{ marginLeft: 10 }}>{layer.layerName}</Typography>
      </div>
    ))
  }


  render() {
    const { onBack,renderDialogContent, renderDialogActions, title, subTitle, dialogContent } = this.props;
    return (
      <Dialog
        onEnter={() => this.submitText = ""}
        open={this.props.open}
        onClose={() => this.props.onClose(false)}
        style={{
          ...this.props.style,
          padding: this.props.theme.spacing(2),
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: onBack ? "space-between" : "flex-end" }}>
          {onBack ?
            <IconButton style={{ display: "flex", placeSelf: "flex-end", marginTop: 10, marginRight: 10 }} >
              <ArrowBackIcon onClick={() => this.props.onBack()} style={{ fontSize: 35 }}></ArrowBackIcon>
            </IconButton> : null
          }
          <IconButton style={{ display: "flex", placeSelf: "flex-end", marginTop: 10, marginRight: 10 }} >
            <CloseOutlinedIcon onClick={() => this.props.onClose(false)} style={{ fontSize: 35 }}></CloseOutlinedIcon>
          </IconButton>
        </div>
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 0,
            fontWeight: 500
          }}
        >
          {title}
        </Typography>
        {subTitle ? 
          <Typography
            variant="caption"
            style={{
              textAlign: "center",
              color: "#A2BAD2",
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "30px"
            }}
          >
            {subTitle}
          </Typography>
          :
          null}
        <DialogContent>
          {dialogContent ? dialogContent : renderDialogContent()}
        </DialogContent>
        <DialogActions>
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTheme(CustomDialog);