import React, { Component } from "react";
import {
  List,
  ListItem,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

class LayerControlsList extends Component {

  renderControls() {
    let keyCounter = 0;
    return this.props.layerControls.map(layerControl => (
      <ListItem
        key={keyCounter++}
        style={{
          paddingBottom: 4,
          paddingTop: 4
        }}
      >
        {layerControl}
      </ListItem>
    ));
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { onDialogIsOpen } = this.props;

    return (
      <Box style={{ 
        height: '100%', 
        width: 370, 
        display: "flex", 
        flexDirection: "column",
        pointerEvents: 'auto',
        justifyContent: "space-between"
      }}>
        <div padding={2} style={{
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '94%', // this works for the scrollbar. But it could be implemented better using flexbox i think
          pointerEvents: 'auto',
        }}>
          <div
            style={{
              ...this.props.style,
              padding: this.props.theme.spacing(2),
              display: "flex",
              textAlign: "left",
              height: "60px",
              justifyContent: "left",
              alignItems: "center",
              marginTop: 20
            }}
          >
            <Typography
              fontWeight="fontWeightBold"
              style={{
                fontSize: "25px",
                fontWeight: "500"
              }}
            >
              {this.Capitalize(this.props.cityName)}
            </Typography>
          </div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                backgroundColor: "#F2F6F9",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
                paddingLeft: "20px",
                borderRadius: "10px",
                fontSize: "12px",
                fontWeight: "bold"
              }}
            >
              {this.props.amountOfLayersSelected} / {this.props.amountOfLayers}{" "}
              Layers Selected
            </div>
          </ListItem>
          <List
            className="layerBox"
            style={{
              overflow: "auto",
            }}
          >
            {this.renderControls()}
            <ListItem>
              <Box padding={0}>
                <Button
                  variant="text"
                  style={{color: "#4a5bbb"}}
                  onClick={() => onDialogIsOpen(true)}
                >
                 + Add new layer
                </Button>
              </Box>
            </ListItem>
          </List>
        </div>
        <FormControlLabel
          style = {{marginLeft: 5, marginBottom: 10 }}
          control={<Checkbox color="primary"  name="Checkbox hidden layers" checked={this.props.displayHiddenLayers} onChange={() => this.props.setDisplayHiddenLayers()} />}
          label="Show disabled layers"
        />
      </Box>
    );
  }
}

export default withTheme(LayerControlsList);
