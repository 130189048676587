import { clone } from "../../../utils/geo/operations";

export default class Layerstack {
  constructor(maxN, setLayer) {
    this.maxN = maxN;
    this.current = -1;
    this.stack = new Array(maxN);
    this.setLayer = setLayer;
  }

  getSize() {
    let count = 0;
    this.stack.forEach(i => {
      if (i.length > 0) count++
    })
    return count
  }

  push(layers) {
    if (this.current === this.maxN-1) {
      this.stack.splice(0, 1);
      this.stack.push(clone(layers));
    } else {
      this.current += 1;
      this.stack[this.current] = clone(layers);
    }
    this.setLayer(this.stack[this.current]);
  }

  undo(isSilentUndo) {
    if (!this.canUndo()) return;
    const { stack } = this;
    if (isSilentUndo) this.setLayer(clone(stack[--this.current]));
    else this.setLayer(clone(stack[--this.current]));
  }

  redo() {
    const { stack } = this;
    if (!this.canRedo()) return;
    this.setLayer(clone(stack[++ this.current]));
  }

  clear() {
    const layers = this.stack[this.current];
    this.stack = new Array(this.maxN);
    this.current = -1;
    this.push(layers);
  }

  canRedo() {
    if (this.current >= this.getSize() - 1) return false;
    return true;
  }

  canUndo() {
    if (this.current <= 0) return false;
    return true;
  }
}
