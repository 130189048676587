import axios from "axios";
import { GET_ZONES_URL, SAVE_ZONES_URL } from "../constants/remotes";

// Returns obj { npz, add, sub }
export const getCityZones = async (cityName, companyName, authToken) => {
  const res = await axios.get(GET_ZONES_URL, {
    params: { companyName, cityName },
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });

  return res.data;
};

export const saveCityZones = async (
  cityName,
  companyName,
  zones,
  deletedLayers,
  authToken
) => {
  const res = await axios.post(
    SAVE_ZONES_URL,
    { cityName, companyName, newZones: zones, deletedLayers },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return res.data;
};
