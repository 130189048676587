import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { GET_CITIES_URL } from "../../constants/remotes";
import axios from "axios";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { withTheme } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import AppSplash from "../../assets/images/appsplash.png";

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  valid: false
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.goToHome();
      } 
    });
  }

  async getCompany() {
    if (this.props.firebase.auth.currentUser) {
      const res = await axios.get(
        GET_CITIES_URL,
        {
          headers: {
            Authorization: `Bearer ${await this.props.firebase.auth.currentUser.getIdToken()}`
          }
        });
      const company = Object.keys(res.data.cityAccess)[0];
      if (!company) this.props.enqueueSnackbar("You do not have access to any cities", { variant: "error" });
      return company
    } 
    else {
      console.log("not logged in");
    }
  }

  async goToHome() {
    this.setState({valid: false}); // disabling signin button, but does not delete current pass/user before user is sent further.
    let company = await this.getCompany();
    if (company) {
      this.props.history.push(`/home/cities/`+ company);
      this.setState({ ...INITIAL_STATE });
    } else {
      this.setState({valid: true})
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.goToHome()
      })
      .catch(error => {
        this.props.enqueueSnackbar(error.message, { variant: "error" });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if (
      (this.state.email !== "" &&
        event.target.name === "password" &&
        event.target.value !== "") ||
      (this.state.password !== "" &&
        event.target.name === "email" &&
        event.target.value !== "")
    ) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  render() {
    return (
      <Grid container style={{ height: "100vh", width: "100%" }}>
        <Grid item xs style={{ backgroundColor: "#FFF" }}>
          <Container
            component="main"
            maxWidth="xs"
            style={{ paddingLeft: 0, paddingRight: "3px" }}
          >
            <div
              style={{
                marginTop: this.props.theme.spacing(20),
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold", color: "#474747" }}
              >
                Log on Geowiz
              </Typography>
              <form
                onSubmit={this.onSubmit}
                noValidate
                style={{ marginTop: this.props.theme.spacing(3) }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.onChange}
                />

                <Grid container style={{ marginTop: "30px" }}>
                  <Grid
                    item
                    xs
                    style={{ maxWidth: "150px", marginRight: "25px" }}
                  >
                    <Button
                      disabled={!this.state.valid}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        margin: this.props.theme.spacing(2, 0, 2),
                        padding: "8px"
                      }}
                    >
                      Sign In
                    </Button>
                  </Grid>
                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Typography variant="body2" style={{ color: "#77797E" }}>
                  {"Need a Geowiz account? "}
                  <Link href={ROUTES.SIGN_UP} variant="body2">
                    {"Sign up"}
                  </Link>
                </Typography>
              </form>
            </div>
          </Container>
        </Grid>
        {window.innerWidth > 800 ? (
          <Grid
            item
            xs={6}
            style={{ backgroundColor: "#EFEFFA", overflow: "hidden" }}
          >
            <img
              alt="appslash"
              src={AppSplash}
              style={{ height: "600px", transform: "translate(150px, 75px)" }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

const SignInForm = compose(
  withTheme,
  withRouter,
  withFirebase,
  withSnackbar
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
