import React, { useState } from 'react';
import { Button, DialogActions, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import CustomDialog from './CustomDialog';

function DownloadLayerDialog({ isOpen, onClose, layerId, onDownload, theme }) {
  const [downloadType, setDownloadType] = useState('MultiPolygon')
  const handleDownload = () => onDownload(layerId, downloadType)

  const renderDialogContent = () => {
    return (
      <FormControl component="fieldset">
        <Typography variant="caption" style={{ marginTop: 8 }}>
          Export layer as one MultiPolygon or a list of Polygons. Choose MultiPolygon for the previous default setting.
        </Typography>
        <FormLabel component="legend">Download Type</FormLabel>
        <RadioGroup
          name="Download type"
          value={downloadType}
          onChange={evt => setDownloadType(evt.target.value)}
        >
          <FormControlLabel
            value="MultiPolygon"
            control={<Radio />}
            label="MultiPolygon"
          />
          <FormControlLabel
            value="Polygon"
            control={<Radio />}
            label="Polygon"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: theme.spacing(2),
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column"
        }}
      >
        <Button
          style={{ backgroundColor: "#4a5bbb", color: "white", marginRight: 10, marginBottom: 5, marginTop: 5}}
          variant="contained"
          onClick={handleDownload}
        >
          Download
        </Button>
      </DialogActions>
    )
  }

  return (
    <CustomDialog
      open={isOpen}
      title='Download layer'
      onClose={onClose}
      renderDialogContent={renderDialogContent}
      renderDialogActions={renderDialogActions}
    />
  )
}

export default withTheme(DownloadLayerDialog)
