import React, { Component } from "react";
import {
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import CustomDialog from "./CustomDialog";
import { withTheme } from "@material-ui/core/styles";

class RenameDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layerName: props.layerName
    }; 
  }

  handleNameChange = event => {
    this.setState({ layerName: event.target.value });
  };

  renderDialogContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 32,
          paddingLeft: 32,
          paddingRight: 32
        }}
      >
        <TextField
          style={{ marginBottom: 40, minWidth: 300 }}
          autoFocus
          label="Name"
          autoComplete={"off"}
          margin="dense"
          id="name"
          value={this.state.layerName}
          onChange={this.handleNameChange}
        ></TextField>
      </div>
    );
  };

  renderDialogActions = () => {
    return (
      <DialogActions
        style={{
          margin: this.props.theme.spacing(2)
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#4a5bbb", color: "white" }}
          onClick={() => this.props.renameLayer(this.state.layerName.trim())}
        >
          Rename Layer
        </Button>
      </DialogActions>
    );
  };

  render() {
    const { renameLayer, setShowRenameDialog, open } = this.props;
    return (
      <CustomDialog
        open={open}
        onClose={setShowRenameDialog}
        onSubmit={renameLayer}
        renderDialogContent={this.renderDialogContent}
        renderDialogActions={this.renderDialogActions}
        title={"Rename Layer"}
      />
    );
  }
}

export default withTheme(RenameDialog);
