import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import LICENSES from '../../constants/licenses.json';

const LicensePage = () => {
  console.log(LICENSES);
  return (
    <div>
      <h1>Licenses</h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Package</TableCell>
              <TableCell align='right'>License</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(LICENSES).map(([key, { licenses, licenseUrl, repository }]) => (
              <TableRow key={key}>
                <TableCell>
                  <a href={repository}>{key}</a>
                </TableCell>
                <TableCell align='right'>
                  <a href={licenseUrl}>{licenses}</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LicensePage;
