import { isLocalhost } from "../utils/env";

export const GET_CITIES_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/getcities"
  : "/api/getcities";
export const GET_ZONES_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/getzones"
  : "/api/getzones";
export const SAVE_ZONES_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/savezones"
  : "/api/savezones";
export const REQUEST_ACCESS_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/requestAccess"
  : "/api/requestAccess";
export const ADD_USER_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/addUser"
  : "/api/addUser";
export const DELETE_USER_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/deleteUser"
  : "/api/deleteUser";
export const UPDATE_ACCESS_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/updateAccess"
  : "/api/updateAccess";
export const GET_USERS_ACCESS = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/getUsersAccess"
  : "/api/getUsersAccess";
export const SEND_MAIL_URL = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/sendMail"
  : "/api/sendMail";
export const ADD_NEW_CITY = isLocalhost
  ? "http://localhost:5001/geowiz-prod/us-central1/main/api/addNewCity"
  : "/api/addNewCity";
export const FETCH_MDS_POLICIES = isLocalhost
  ? "https://us-central1-geowiz-prod.cloudfunctions.net/main/adminapi/getMDSPolicies"
  : "https://us-central1-geowiz-prod.cloudfunctions.net/main/adminapi/getMDSPolicies";
