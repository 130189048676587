import React, { useState } from "react";
import { withTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";


const StyleToggler = ({ setMapStyle, mapStyle, mapStyles }) => {
  const [styleImage, setStyleImage] = useState(mapStyles["standardStyle"].image);
  const [displayAll, setDisplayAll] = useState(false);


  const StyleImageButton = ({ styleImage, onClick }) => (
    <IconButton onClick={onClick} style={{
      paddingTop: 0,
      paddingLeft: 5,
      paddingRight: 0,
      paddingBottom: 0,
      marginTop: 10, marginBottom: 10
    }}>
      <img alt="mapstyle" src={styleImage} style={{ width: 55 }}>
      </img>
    </IconButton>
  )

  const filterStyles = () => {
    const list = Object.keys(mapStyles)
      .filter(style =>  mapStyles[style].url !== mapStyle);
    return list;

  }


  return (
    <React.Fragment>
      <div
        style={{
          margin: 8,
          marginBottom: 16,
          padding: 8,
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          alignItems: "center"
        }}
        className="hideInSS"
      >
        {displayAll &&
          filterStyles().map(style => (
            <StyleImageButton
              key={mapStyles[style].url}
              styleImage={mapStyles[style].image}
              onClick={() => {
                setStyleImage(mapStyles[style].image)
                setMapStyle(mapStyles[style].url)
                setDisplayAll(!displayAll);
              }}
            />
          ))
        }
        <StyleImageButton
          styleImage={styleImage}
          onClick={() => {
            setDisplayAll(!displayAll)
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default withTheme(StyleToggler);
