import {
  getGeosBoundless,
  getBbox,
  createFc,
  filterPolygons,
  buffPolygon,
  combineLayers,
  simplifyPolygons,
} from "../../utils/geo/operations";
import { LAYER_TYPE_OPS } from "./Map";
import osmtogeojson from "osmtogeojson";

export default async function fetchOSMData(input) {
  let polygons = [];
  let types = getTypes(input.categories);
  try {
    const bounds = getBounds(input.layers);
    let geos = await fetchGeos(types, getBbox(input.bbox));
    let polygons = applyBufferProp(types, geos.Polygons);
    polygons = polygons.map(pol => buffPolygon(pol, pol.properties.buffer));
    polygons = filterPolygons(polygons, bounds);
    polygons = simplifyPolygons(polygons, 0.00001);
    return createFc(polygons)
  } catch (error) {
    // on error will return polygons fetched so far,
    // which will enqueue a snackbar later if empty
    return createFc(polygons);
  }
}

function getBounds(layers) {
  let l = layers.filter(layer => layer.layerType === LAYER_TYPE_OPS);
  l = l.map(l => l.data);
  l = combineLayers(l);
  return l;
}

function getPedestrianLineStringPols(lineStrings) {
  let pols = []
  lineStrings.forEach((ls) => {
    if (ls.properties.highway && ls.properties.highway === "pedestrian") {
      const pol = buffPolygon(ls, 10);
      pol.properties["highway"] = "pedestrian";
      pols.push(pol);
    }
  })
  return pols;
}

async function fetchGeos(categories, bbox, retries = 0) {
  if (retries >= 5) return { polygons: [] };
  try {
    let url = getUrl(categories, bbox);
    let r = await fetch(url);
    let data = await r.json();
    data = osmtogeojson(data);
    let geos = getGeosBoundless(data);
    let lineStringsPol = getPedestrianLineStringPols(geos.LineStrings);
    geos.Polygons = geos.Polygons.concat(lineStringsPol);
    return geos;
  } catch (error) {
    await sleep(1000 * 5);
    return fetchGeos(categories, bbox, retries + 1);
  }
}

function parseBuffer(str) {
  return parseInt(str.split(" ")[0]);
}

function applyBufferProp(types, pols) {
  pols.forEach(pol => {
    types.forEach(type => {
      if (pol.properties[type.key] === type.value) {
        pol.properties["buffer"] = parseBuffer(type.buffer);
        return;
      }
    });
  });
  return pols;
}

const getTypes = categories => {
  let types = [];
  for (const key of Object.keys(categories)) {
    if (!categories[key].checked) continue;
    for (let type of categories[key].types) {
      type = {
        key: type.key,
        value: type.value,
        buffer: categories[key].buffer
      };
      types.push(type);
    }
  }
  return types;
};

function getUrl(types, bbox) {
  const overpass_query = `https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];
    (
    ${types
    .map(type => buildOSMString("node", type.key, type.value, bbox))
    .join("")}
    ${types
    .map(type => buildOSMString("way", type.key, type.value, bbox))
    .join("")}
    ${types
    .map(type => buildOSMString("rel", type.key, type.value, bbox))
    .join("")}
    );
    out body;
    >;
    out skel qt;
    `;
  return overpass_query;
}

function buildOSMString(type, k, v, bbox) {
  if (v) {
    return `${type}["${k}" = "${v}"](${bbox});`;
  } else return `${type}["${k}"](${bbox});`;
}

async function sleep(ms) {
  console.log("Sleeping for " + ms * 0.001 + " seconds");
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
